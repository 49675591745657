import styled from "@emotion/styled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Container} from "@mui/material";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {observer} from "mobx-react";
import React from "react";
import {INotificationController} from "views/components/notification/notification.controller";

const MainWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: column;
	gap: 8px;

	span {
		color: var(--text-dark);
		font-size: 20px;
		font-style: normal;
		font-weight: 800;
		line-height: normal;
	}
`;

const NotificationElement = styled.div`
	background: var(--success-color);
	width: 100%;
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 52px;
`;

export const Notification: React.FC = observer(() => {
	const {notifications} = useViewController<INotificationController>(
		Bindings.NotificationController
	);
	return (
		<MainWrapper>
			{notifications.map((notification) => (
				<NotificationElement key={notification.uuid}>
					<Container>
						<Content>
							<span>{notification.message}</span>
							<CheckCircleIcon />
						</Content>
					</Container>
				</NotificationElement>
			))}
		</MainWrapper>
	);
});
