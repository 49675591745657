import {Bindings} from "data/constants/bindings";
import type {IRankingsApiProvider} from "data/providers/api/rankings.api.provider";
import type {IContest} from "data/types/contest";
import type {IGameBar, IRanking, IRankingsRequestPayload} from "data/types/rankings";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";

export interface IRankingsStore {
	get isLoading(): boolean;

	get selectedContest(): IContest | undefined;

	set selectedContest(value: IContest | undefined);

	get gameBar(): IGameBar | undefined;

	get rankings(): IRanking[];

	get userRow(): IRanking | null;

	get hasNextPage(): boolean;

	get isOverall(): boolean;

	set isOverall(value: boolean);

	fetchRankings(contestId: number, clear?: boolean): Promise<void>;

	fetchLoadMore(contestId: number): Promise<void>;

	fetchGameBar(): Promise<void>;
}

@injectable()
export class RankingsStore implements IRankingsStore {
	@observable private _isOverall: boolean = false;
	@observable private _gameBar: IGameBar | undefined;
	@observable private _selectedContest: IContest | undefined;
	@observable private _isLoading: boolean = false;
	@observable private _rankings: IRanking[] = [];
	@observable private _userRow: IRanking | null = null;
	@observable private _page: number = 1;
	@observable private _hasNextPage: boolean = false;

	constructor(
		@inject(Bindings.RankingsApiProvider) private _rankingsApiProvider: IRankingsApiProvider
	) {
		makeAutoObservable(this);
	}

	public get isOverall(): boolean {
		return this._isOverall;
	}

	public set isOverall(value: boolean) {
		this._isOverall = value;
	}

	public get userRow(): IRanking | null {
		return this._userRow;
	}

	get hasNextPage(): boolean {
		return this._hasNextPage;
	}

	public get rankings(): IRanking[] {
		return this._rankings;
	}

	public get gameBar(): IGameBar | undefined {
		return this._gameBar;
	}

	public get selectedContest(): IContest | undefined {
		return this._selectedContest;
	}

	public set selectedContest(value: IContest | undefined) {
		this._selectedContest = value;
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	@action
	public async fetchRankings(contestId: number, clear = true): Promise<void> {
		this._isLoading = true;

		if (clear) {
			this.clearRankings();
		}

		try {
			const payload: IRankingsRequestPayload = {
				contestId,
				page: this._page,
			};

			const {data} = await this._rankingsApiProvider.rankings(payload, this.isOverall);

			runInAction(() => {
				this._rankings = this.concatRankings(data.success.rankings);
				this._userRow = data.success.user;
				this._hasNextPage = data.success.nextPage;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			this._isLoading = false;
		}
	}

	@action
	public fetchLoadMore(contestId: number): Promise<void> {
		this._page += 1;
		return this.fetchRankings(contestId, false);
	}

	public async fetchGameBar(): Promise<void> {
		const {data} = await this._rankingsApiProvider.gameBar();

		runInAction(() => {
			this._gameBar = data.success;
		});
	}

	@action
	private concatRankings(rankings: IRanking[]) {
		if (this._page === 1) {
			return rankings;
		}
		return [...this.rankings, ...rankings];
	}

	@action
	private clearRankings() {
		this._page = 1;
		this._rankings = [];
		this._hasNextPage = false;
		this._userRow = null;
	}
}
