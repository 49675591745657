import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {Platforming} from "data/utils/platforming";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface IAppHeaderController extends ViewController {
	get i18n(): ILocalizationStore;

	get isVisible(): boolean;

	closeApp: () => void;
}

@injectable()
export class AppHeaderController implements IAppHeaderController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public closeApp = () => {
		const type = Platforming.REDIRECT();
		window.parent?.postMessage(JSON.stringify({type}), "*");
	};

	public get isVisible(): boolean {
		return Platforming.isApp;
	}
}
