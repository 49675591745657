import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";

export interface ISecretGateController extends ViewController {
	handleSecretClick: () => void;

	get i18n(): ILocalizationStore;
}

@injectable()
export class SecretGateController implements ISecretGateController {
	private static _count = 0;

	constructor(@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore) {}

	static get IS_SECRET_PASSED() {
		const IS_SECRET_ENABLED = Boolean(
			JSON.parse(process.env.REACT_APP_IS_SECRET_ENABLED || "false")
		);

		if (IS_SECRET_ENABLED) {
			return Boolean(JSON.parse(sessionStorage.getItem("isSecretPassed") || "false"));
		}

		return true;
	}

	handleSecretClick = () => {
		if (SecretGateController.IS_SECRET_PASSED) {
			return;
		}

		SecretGateController._count += 1;

		if (SecretGateController._count >= 10) {
			sessionStorage.setItem("isSecretPassed", "true");
			window.location.reload();
		}
	};
}
