import {Bindings} from "data/constants/bindings";
import {Locale} from "data/enums";
import type {IHttpClientService} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";

export interface ISessionController extends ViewController {
	get isSessionChecked(): boolean;
}

@injectable()
export class SessionController implements ISessionController {
	private _userLocale: Locale = navigator.language as Locale;
	@observable _isSessionChecked = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ApiHTTPClient) private _apiHTTPClient: IHttpClientService,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}

	@action async init() {
		try {
			await this._i18nStore.switchLocale({
				locale: await this.defineLocale(),
			});
		} catch (_err) {
			// Show error message to a user
		}

		// Set locale that will be appended to each request
		this._apiHTTPClient.setLocale(this._i18nStore.lang);

		void this._userStore
			.requestUser()
			.catch(() => {
				// Do nothing as the error is expected when a user isn't authorized
			})
			.then(() =>
				runInAction(() => {
					this._isSessionChecked = true;
				})
			);
	}

	/**
	 * The method is to define a user's locale. It can be done by:
	 * 1) navigator.language
	 * 2) Site URL
	 * 3) Some JSON or API request settings
	 * 4) Whatever else
	 */
	private async defineLocale(): Promise<Locale> {
		this._userLocale = await Promise.resolve(Locale.EN_US);
		return this._userLocale;
	}
}
