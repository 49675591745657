export abstract class CardFlip {
	private static readonly storageKey = "usga_card_flips";
	public static saveFlip(contestId: number, cardEntityId: number) {
		if (this.getIsFlipped(contestId, cardEntityId)) {
			return;
		}
		const saveData = this.generateSaveData(contestId, cardEntityId);
		const data = this.getSavedFromStorage();

		data.push(saveData);
		this.saveDataToStorage(data);
	}

	public static ClearFlips() {
		localStorage.setItem(this.storageKey, "[]");
	}

	public static getIsFlipped(contestId: number, cardEntityId: number): boolean {
		const data = this.getSavedFromStorage();
		return data.includes(this.generateSaveData(contestId, cardEntityId));
	}

	private static getSavedFromStorage(): string[] {
		return JSON.parse(localStorage.getItem(this.storageKey) || "[]") as string[];
	}

	private static generateSaveData(contestId: number, cardEntityId: number): string {
		return `${contestId}:${cardEntityId}`;
	}

	private static saveDataToStorage(data: string[]) {
		localStorage.setItem(this.storageKey, JSON.stringify(data));
	}
}
