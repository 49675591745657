import {COUNTRY_ISO_CODES, FLAGS_URL, IMAGES_URL} from "data/constants";
import {IContest} from "data/types/contest";
import {DateTime} from "luxon";
import type {ComponentType} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;

export const copyToClipboard = async (content: string) => {
	try {
		await navigator.clipboard.writeText(content);
		return true;
	} catch (_err) {
		return false;
	}
};

export function convertISOCountry(code: string) {
	const lowerCase = code.toLowerCase();
	return COUNTRY_ISO_CODES[lowerCase] || lowerCase;
}

export function getImageFromBackend(name: string | undefined | null): string {
	if (!name) {
		return "";
	}
	return `${IMAGES_URL}${name}`;
}

export function getFlagImage(name: string | undefined | null): string {
	if (!name) {
		return "";
	}

	return `${FLAGS_URL}${name}.png`;
}

export const wait = (durationMS: number) =>
	new Promise((resolve) => setTimeout(resolve, durationMS));

export function prepareDateForContest(contest: IContest | undefined): string {
	if (!contest) {
		return "";
	}
	const startDate = DateTime.fromISO(contest.dateStart);
	const endDate = DateTime.fromISO(contest.dateEnd);

	const sameMonth = startDate.month === endDate.month;

	if (sameMonth) {
		const month = startDate.toFormat("LLLL");
		const startDay = startDate.toFormat("dd");
		const endDay = endDate.toFormat("dd");
		return `${month} ${startDay} - ${endDay}`;
	}

	const startDay = startDate.toFormat("LLLL dd");
	const endDay = endDate.toFormat("LLLL dd");

	return `${startDay} - ${endDay}`;
}

export function preparePoints(points: string | undefined | null) {
	if (points === undefined || points === null) {
		return "--";
	}

	return String(parseFloat(points));
}

export * from "data/utils/countdown";
export * from "data/utils/socialShare";
