import {Bindings} from "data/constants/bindings";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IQuestion} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

interface IParams {
	questionId: number;
}

export interface IOutcomePointsController extends ViewController<IParams> {
	get i18n(): ILocalizationStore;

	get question(): IQuestion | undefined;
}

@injectable()
export class OutcomePointsController implements IOutcomePointsController {
	@observable private _questionId: number = Number.MAX_SAFE_INTEGER;
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore
	) {
		makeAutoObservable(this);
	}

	get contest() {
		return this._contestsStore.selectedContest;
	}

	get question(): IQuestion | undefined {
		return this.contest?.questions.find((question) => question.questionId === this._questionId);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._questionId = param.questionId;
	}

	onChange(param: IParams): void {
		this._questionId = param.questionId;
	}
}
