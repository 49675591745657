export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum ModalType {
	ERROR,
	CONFIRM,
	TEAM_SAVE,
	TEAM_SAVE_OUTCOME,
	SECONDARY_REGISTRATION,
	PRE_OPEN_PACK,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum LeaguePrivacy {
	PUBLIC = "public",
	PRIVATE = "private",
}

export enum LeagueType {
	REGULAR = "regular",
	OVERALL = "overall",
}

export enum LeagueStatus {
	COMPLETE = "complete",
	PLAYING = "playing",
	SCHEDULED = "scheduled",
}

export enum ContestType {
	Player = "player",
	Prop = "prop",
}

export enum ContestStatus {
	Scheduled = "scheduled",
	Playing = "active",
	Complete = "complete",
}

export enum TournamentStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
	Cancelled = "cancelled",
}

export enum PostMessageType {
	Auth = "usga-auth",
	Redirect = "gs-redirect",
	Close = "gs-close",
	IframeReady = "gs-iframe-ready",
}
export enum Platform {
	Web = "web",
	App = "app",
}
