import {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IAnswersApiProvider,
	TShareImageResponse,
} from "data/providers/api/answers.api.provider";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {INotificationsStore} from "data/stores/notifications/notifications.store";
import type {IAnswerPayload, IAnswersResponse, IFlipState} from "data/types/contest";
import {createConnextraScriptTag} from "data/utils/connextra";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction, toJS} from "mobx";

export interface IAnswersStore {
	get lineup(): number[];

	get isTeamSaved(): boolean;

	get isLoading(): boolean;

	set isLoading(value: boolean);

	get isFirstPackOpen(): boolean;

	set isFirstPackOpen(value: boolean);

	get isFlipCardAnimation(): boolean;

	set isFlipCardAnimation(value: boolean);

	get flipPayload(): IFlipState | undefined;

	get openCards(): number[];

	set openCards(value: number[]);

	fetchAnswers(contestId: number): Promise<void | IAnswersResponse>;

	fetchAnswersNoStore(contestId: number): Promise<IAnswersResponse>;

	saveAnswers(
		userID: number | undefined,
		contestId: number,
		payload: IAnswerPayload
	): Promise<void>;

	generateLineup(contestId: number): Promise<void>;

	generateSharingImage(contestId: number): Promise<AxiosResponse<TShareImageResponse>>;
}

@injectable()
export class AnswersStore implements IAnswersStore {
	@observable private _isFirstPackOpen: boolean = false;
	@observable private _isFlipCardAnimation: boolean = false;
	@observable private _contestAnswers: IAnswersResponse | undefined;
	@observable private _isLoading: boolean = false;
	@observable private _flipPayload: IFlipState | undefined;
	@observable private _openCards: number[] = [];

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.AnswersApiProvider) private _answersApiProvider: IAnswersApiProvider,
		@inject(Bindings.NotificationsStore) private _notificationsStore: INotificationsStore
	) {
		makeAutoObservable(this);
	}

	public get openCards(): number[] {
		return this._openCards;
	}

	public set openCards(value: number[]) {
		this._openCards = value;
	}

	public get flipPayload(): IFlipState | undefined {
		return this._flipPayload;
	}

	public get isFlipCardAnimation(): boolean {
		return this._isFlipCardAnimation;
	}

	public set isFlipCardAnimation(value: boolean) {
		this._isFlipCardAnimation = value;
	}

	public get isFirstPackOpen(): boolean {
		return this._isFirstPackOpen;
	}

	public set isFirstPackOpen(value: boolean) {
		this._isFirstPackOpen = value;
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public set isLoading(value: boolean) {
		this._isLoading = value;
	}

	public get lineup(): number[] {
		return this._contestAnswers?.lineup || [];
	}

	public get isTeamSaved(): boolean {
		return this._contestAnswers?.teamSaved === true;
	}

	@action
	public async generateLineup(contestId: number): Promise<void> {
		try {
			const {data} = await this._answersApiProvider.generateCards(contestId);

			runInAction(() => {
				this._contestAnswers = data.success;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	@action
	public async saveAnswers(
		userID: number | undefined,
		contestId: number,
		payload: IAnswerPayload
	): Promise<void> {
		try {
			this._isLoading = true;
			const {data} = await this._answersApiProvider.saveAnswers(contestId, payload);

			if (payload.swap) {
				this.getDiffBetweenSwapAndResponse(payload.swap, data.success.lineup);

				if (this.flipPayload) {
					this._isFlipCardAnimation = true;
				}
			}

			runInAction(() => {
				this._contestAnswers = data.success;
			});

			if (!userID) {
				return;
			}
			if (payload.swap) {
				createConnextraScriptTag(`f2p_fantasyflip_flipcard?AccountID=${userID}`);
			} else {
				createConnextraScriptTag(`f2p_fantasyflip_savepack?AccountID=${userID}`);
			}
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
				this._isFirstPackOpen = false;
			});
		}
	}

	@action
	public async fetchAnswers(contestId: number): Promise<void | IAnswersResponse> {
		try {
			this._isLoading = true;
			const {data} = await this._answersApiProvider.fetchAnswers(contestId);

			runInAction(() => {
				this._contestAnswers = data.success;
			});
			return Promise.resolve(data.success);
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	public async fetchAnswersNoStore(contestId: number): Promise<IAnswersResponse> {
		try {
			this._isLoading = true;
			const {data} = await this._answersApiProvider.fetchAnswers(contestId);
			return Promise.resolve(data.success);
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}

	public generateSharingImage(contestId: number) {
		return this._answersApiProvider.generateSharingImage(contestId);
	}

	private getDiffBetweenSwapAndResponse(swapId: number, lineup: number[]) {
		const swapIndex =
			toJS(this._contestAnswers?.lineup)?.findIndex((id) => id === swapId) ?? -1;

		if (swapIndex === -1) {
			return;
		}

		const diff = {
			from: swapId,
			to: lineup[swapIndex],
		};

		if (diff.from && diff.to) {
			this._flipPayload = diff;
		}
	}
}
