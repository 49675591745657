import styled from "@emotion/styled";
import {Button, FormControl, FormControlLabel, Modal} from "@mui/material";
import modalTopImageDesktop from "assets/img/modals/modal_registration_desktop.jpg";

import modalTopImageMobile from "assets/img/modals/modal_registration_mobile.jpg";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {createConnextraScriptTag} from "data/utils/connextra";
import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {Exist} from "views/components/exist/exist.component";
import {Checkbox, ErrorText, Input} from "views/components/form";
import {ModalContent, ModalText, ModalTitle} from "views/components/modals/common";
import {IModalSecondaryRegistrationController} from "views/components/modals/modal_secondary_registration/modal_secondary_registration.controller";

const SModalContent = styled(ModalContent)`
	p {
		font-size: 16px;
		color: var(--text-dark);
	}

	button {
		max-width: unset;
	}

	.MuiStack-root {
		position: relative;

		.MuiFormControlLabel-asterisk {
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	@media screen and (max-width: 640px) {
		padding: 16px;
		h4 {
			font-size: 20px;
		}

		p {
			font-size: 14px;
			color: var(--text-dark);
		}
	}
`;

const TopImage = styled.img`
	height: 160px;
	width: 100%;

	&.desktop {
		display: block;
	}

	&.mobile {
		display: none;
	}

	@media screen and (max-width: 640px) {
		height: auto;
		&.desktop {
			display: none;
		}

		&.mobile {
			display: block;
		}
	}
`;

const Form = styled.form`
	width: 100%;
	max-width: 320px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	justify-content: flex-start;
	align-items: flex-start;
`;

const SFormControlLabel = styled(FormControlLabel)`
	text-align: left;
	margin-right: 0;
	margin-left: 0;

	p {
		color: var(--text-dark);
		font-size: 13px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		padding-left: 12px;
		text-align: justify;
		padding-right: 12px;

		a {
			text-decoration: underline;
		}
	}
`;

export const ModalSecondaryRegistration: React.FC = observer(() => {
	const {i18n, isOpen, error, handleFormSubmit, onFormChange} =
		useViewController<IModalSecondaryRegistrationController>(
			Bindings.ModalSecondaryRegistrationController
		);

	useEffect(() => {
		if (isOpen) {
			createConnextraScriptTag("f2p_fantasyflip_regstart");
		}
	}, [isOpen]);

	return (
		<Modal open={isOpen}>
			<SModalContent>
				<TopImage className="mobile" src={modalTopImageMobile} alt="Top Image" />
				<TopImage className="desktop" src={modalTopImageDesktop} alt="Top Image" />
				<ModalTitle>
					{i18n.t("modal.secondary_registration.title", "Create a Game Name")}
				</ModalTitle>
				<ModalText>
					{i18n.t(
						"modal.secondary_registration.text",
						"To get started give yourself a game name and accept our game terms to be eligible to win PRIZES"
					)}
				</ModalText>

				<Form onSubmit={handleFormSubmit} onChange={onFormChange}>
					<FormControl fullWidth>
						<Input
							label={i18n.t("form.display_name.label", "Display Name")}
							name="displayName"
							type="text"
							placeholder={i18n.t("form.display_name.placeholder", "Game Name")}
							variant="standard"
							inputProps={{maxLength: 40}}
							required={true}
						/>
					</FormControl>
					<SFormControlLabel
						control={<Checkbox required />}
						label={
							<p
								// disabled as loco is safe place to render
								/* eslint-disable react/no-danger */
								dangerouslySetInnerHTML={{
									__html: i18n.t(
										"form.terms.label",
										"I have read and accepted the Terms & Conditions"
									),
								}}
							/>
						}
					/>

					<Exist when={error}>
						<ErrorText>{error}</ErrorText>
					</Exist>

					<Button type="submit" fullWidth>
						{i18n.t("modal.secondary_registration.action", "Play Now")}
					</Button>
				</Form>
			</SModalContent>
		</Modal>
	);
});
