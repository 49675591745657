import type {AxiosError} from "axios";
import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import type {IApiResponse} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {extractErrorMessage} from "data/utils";
import {Platforming} from "data/utils/platforming";
import {inject, injectable} from "inversify";
import {isEqual} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {ChangeEvent, SyntheticEvent} from "react";

interface IMyAccountFormElement extends HTMLFormElement {
	displayName: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	goToUSGARedirect: () => void;
	handleFormSubmit: (event: SyntheticEvent<IMyAccountFormElement>) => void;
	handleClearErrorOnChange: () => void;
	handleInputDisplayNameValue: (event: ChangeEvent<HTMLInputElement>) => void;
	handleLogout: () => void;

	get i18n(): ILocalizationStore;

	get isFormDisabled(): boolean;

	get error(): Record<string, string> | null;

	get displayName(): string;

	get user(): IUser;

	get isUpdateDisabled(): boolean;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _displayName = "";
	@observable private _isFormChanged = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
		this._displayName = this._userStore.user!.displayName;
	}

	get isUpdateDisabled() {
		return this.isFormDisabled || !this._isFormChanged;
	}

	get user() {
		return this._userStore.user!;
	}

	get displayName() {
		return this._displayName;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	public goToUSGARedirect = () => {
		const type = Platforming.REDIRECT();
		window.parent?.postMessage(JSON.stringify({type}), "*");
	};

	@action handleInputDisplayNameValue = (event: ChangeEvent<HTMLInputElement>) => {
		this._displayName = event.target.value.replace("@", "");
	};

	@action public handleFormSubmit = (event: SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {displayName, isNotificationsEnabled} = event.currentTarget;

		const validateList = [
			{field: displayName, error: "Please provide your display name", place: "username"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		const payload = {
			displayName: displayName.value,
			isNotificationsEnabled: isNotificationsEnabled.checked,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isFormChanged = false;
				})
			)
			.catch(this.onError);
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._isFormChanged = true;
	};

	@action handleLogout = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore
			.logout()
			.then(() => {
				this.onSuccess();
				window.location.reload();
			})
			.catch(this.onError);
	};

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};
}
