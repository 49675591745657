import {FACEBOOK_ID, SHARE_URL} from "data/constants";
import {ShareType, SocialNetwork} from "data/enums";
import {createConnextraScriptTag} from "data/utils/connextra";
import {identity} from "lodash";

interface IGeneralShare {
	socialNetwork: SocialNetwork;
	type: ShareType.General;
	message?: string;
	data: {userId: number; contestId: number};
}

type IShare = IGeneralShare;

const getTimestamp = () => Date.now().toString().substring(9);

const getEncodedJson = (object: Partial<IShare> & {t: string}) =>
	window.btoa(JSON.stringify(object));

export const getLinkForShare = (shareData: IShare) => {
	const params = {
		user: shareData.data.userId,
		contest: shareData.data.contestId,
		t: getTimestamp(),
	};

	return `${SHARE_URL}en/${shareData.type}/${getEncodedJson(params)}`;
};

const shareFB = (data: IShare) => {
	const link = getLinkForShare(data);

	if (!FACEBOOK_ID) {
		const winWidth = 626;
		const winHeight = 436;
		const winTop = window.screen.height / 2 - winHeight / 2;
		const winLeft = window.screen.width / 2 - winWidth / 2;

		window.open(
			`https://www.facebook.com/sharer/sharer.php?display=popup&u=${link}`,
			"_blank",
			`top=${winTop},left=${winLeft},width=${winWidth}, height=${winHeight},toolbar=0,status=0,scrollbars=no,resizable=1`
		);
		return;
	}

	window?.FB?.ui(
		{
			method: share,
			display: "popup",
			href: link,
		},
		identity
	);
};

const shareTW = ({message = "", ...params}: IShare) =>
	window.open(
		"https://twitter.com/intent/tweet?url=" +
			encodeURIComponent(getLinkForShare(params)) +
			"&text=" +
			encodeURIComponent(message),
		"twitter-share-dialog",
		"width=626,height=436"
	);

const shareMobile = ({message = "", ...params}: IShare) => {
	void navigator
		.share({
			url: getLinkForShare(params),
			text: message,
		})
		.catch((_err) => {
			// Do nothing
		});
};

const callbacks = {
	[SocialNetwork.Twitter]: shareTW,
	[SocialNetwork.Facebook]: shareFB,
	[SocialNetwork.Mobile]: shareMobile,
} as const;

export const share = (data: IShare, userId: number | undefined) => {
	if (userId) {
		createConnextraScriptTag(`f2p_fantasyflip_sharepack?AccountID=${userId}`);
	}
	return callbacks[data.socialNetwork](data);
};
