import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

export interface ILandingBannerController extends ViewController {
	close: () => void;

	get isOpen(): boolean;

	get i18n(): ILocalizationStore;
}

@injectable()
export class LandingBannerController implements ILandingBannerController {
	@observable private _isOpen: boolean = false;
	private readonly _bannerKey: string = "usga_l_b_viewed";

	constructor(@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	public get isOpen(): boolean {
		return this._isOpen;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		this._isOpen = !(JSON.parse(localStorage.getItem(this._bannerKey) || "false") as boolean);
	}

	onChange(param: void): void {
		return;
	}

	public close = () => {
		this._isOpen = false;
		localStorage.setItem(this._bannerKey, "true");
	};
}
