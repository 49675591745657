import {Bindings} from "data/constants/bindings";
import {TournamentStatus} from "data/enums";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IPlayerCardsCommonEntity} from "data/types/common";
import type {IContest, IQuestion} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

export interface IOutcomeCardResultController extends ViewController<IPlayerCardsCommonEntity> {
	get i18n(): ILocalizationStore;

	get question(): IQuestion | undefined;

	get contest(): IContest | undefined;

	get className(): string;

	get points(): string | undefined;
}

@injectable()
export class OutcomeCardResultController implements IOutcomeCardResultController {
	@observable private _position: number = Number.MAX_SAFE_INTEGER;
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: IPlayerCardsCommonEntity): void {
		this._position = param.position;
		this._contestId = param.contestId;
	}

	onChange(param: IPlayerCardsCommonEntity): void {
		this._position = param.position;
		this._contestId = param.contestId;
	}

	get className(): string {
		if (this.tournament?.status === TournamentStatus.Scheduled) {
			return "";
		}
		return String(this.contest?.status || "");
	}

	private get tournament() {
		if (!this.contest) {
			return;
		}
		return this._tournamentsStore.getTournamentById(this.contest.tournamentId);
	}

	get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}

	get points(): string | undefined {
		return (
			this._contestsStore.getPointsPerContestEntity(this._contestId, this.questionId) || "--"
		);
	}

	private get lineup() {
		return this._answersStore.lineup;
	}

	private get questionId(): number {
		return this.lineup[this._position] || -1;
	}

	public get question(): IQuestion | undefined {
		return this.contest?.questions.find((question) => question.questionId === this.questionId);
	}
}
