import {Bindings} from "data/constants/bindings";
import {ContestStatus, TournamentStatus} from "data/enums";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITournament, ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IContestIdParams} from "data/types/common";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

export interface IContestPillController extends ViewController<IContestIdParams> {
	get i18n(): ILocalizationStore;

	get contest(): IContest | undefined;

	get isOpen(): boolean;

	get isContestSoon(): boolean;

	get tournamentStartDate(): string;
}

@injectable()
export class ContestPillController implements IContestPillController {
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore
	) {
		makeAutoObservable(this);
	}

	get isContestSoon(): boolean {
		return this.contest?.status === ContestStatus.Scheduled;
	}
	get tournamentStartDate(): string {
		return this.tournament?.dateStart || new Date().toISOString();
	}

	get isOpen(): boolean {
		return (
			this.tournament?.status === TournamentStatus.Scheduled &&
			this.contest?.status === ContestStatus.Playing
		);
	}

	public get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}

	private get tournament(): ITournament | undefined {
		if (!this.contest) {
			return;
		}

		return this._tournamentsStore.getTournamentById(this.contest.tournamentId);
	}

	dispose(): void {
		return;
	}

	init(param: IContestIdParams): void {
		this._contestId = param.contestId;
	}

	onChange(param: IContestIdParams): void {
		this._contestId = param.contestId;
	}
}
