import styled from "@emotion/styled";
import {Container} from "@mui/material";
import {ReactComponent as FooterLogo} from "assets/img/logos/logo-with-sponsor.svg";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {observer} from "mobx-react";
import React from "react";
import {NavLink} from "react-router-dom";
import {ILocalizationController} from "views/controllers/localization/localization.controller";

const Wrapper = styled.footer`
	width: 100%;
	background: var(--neutral-0);
	border-top: 4px solid var(--primary-default);
	padding: 20px 0;
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;

	&:last-of-type {
		margin-bottom: 0;
	}

	@media screen and (max-width: 600px) {
		flex-direction: column;
		gap: 12px;

		&:last-of-type {
			flex-direction: column-reverse;
		}
	}
`;

const Links = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 8px;

	a {
		color: var(--text-active);
		text-align: right;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-decoration-line: underline;
	}

	.divider {
		width: 0;
		height: 14px;
		border-right: 2px solid var(--text-active);
		position: relative;
		top: 1px;
	}
`;

const Terms = styled.div`
	color: var(--text-dark);
	font-size: 11px;
	line-height: normal;

	a {
		color: var(--text-active);
		font-weight: bold;
		text-decoration: underline;
	}

	@media screen and (max-width: 800px) {
		text-align: center;
	}
`;

export const Footer: React.FC = observer(() => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	return (
		<Wrapper>
			<Container>
				<Content>
					<FooterLogo />
					<Links>
						<NavLink to="/help/t&cs">
							{i18n.t("footer.copy.terms", "Terms & Conditions")}
						</NavLink>
						<div className="divider" />
						<NavLink to="/help/contact-us">
							{i18n.t("footer.copy.contact", "Contact Us")}
						</NavLink>
					</Links>
				</Content>
				<Content>
					<span>{i18n.t("footer.copy.copyright", "© 2024 USGA")}</span>
				</Content>
				<Terms
					dangerouslySetInnerHTML={{
						__html: i18n.t(
							"footer.copy.terms_text",
							`
                 NO PURCHASE NECESSARY. Open only to legal residents of the 50 US/DC, 18 years of age and older. Void
                    where prohibited by law. Promotion starts 9:00 a.m. ET on 5/23/24 and ends 11:59:59 a.m. ET on
                    8/18/24. Includes 8 entry periods. Subject to Official Rules, including how to enter, entry period
                    dates, prize details, and restrictions, click <a
                    href="https://www.usga.org/content/usga/home-page/championships/2024/fantasy-flip-official-rules.html"
                    target="_blank" rel="noreferrer noopener">here</a>.
                    Sponsor: United States Golf Association, 77
                    Liberty Corner Road, Liberty Corner, NJ 07938.
                `
						),
					}}></Terms>
			</Container>
		</Wrapper>
	);
});
