import {AxiosError} from "axios";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import {IRegisterPayloadBase} from "data/providers/api/auth.api.provider";
import {IApiResponse} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {extractErrorMessage} from "data/utils";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";
import React from "react";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";

interface IForm extends HTMLFormElement {
	displayName: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
}

export interface IModalSecondaryRegistrationController extends ViewController {
	handleFormSubmit: (event: React.SyntheticEvent<IForm>) => void;
	onFormChange: () => void;
	close: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;

	get error(): string | undefined;
}

@injectable()
export class ModalSecondaryRegistrationController implements IModalSecondaryRegistrationController {
	@observable private _error: string | undefined = "";

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	public get error(): string | undefined {
		return this._error;
	}

	get isOpen(): boolean {
		if (!SecretGateController.IS_SECRET_PASSED) {
			return false;
		}
		return this._modalsStore.modal === ModalType.SECONDARY_REGISTRATION;
	}

	@action
	public onFormChange = () => {
		this._error = undefined;
	};

	public close = () => {
		this._modalsStore.hideModal();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public handleFormSubmit = (event: React.SyntheticEvent<IForm>): void => {
		event.preventDefault();

		const {displayName} = event.currentTarget;

		// isNotificationsEnabled true by default
		const payload: IRegisterPayloadBase = {
			displayName: displayName.value,
			isNotificationsEnabled: true,
		};

		this._userStore
			.register(payload)
			.then(() => {
				this.close();
			})
			.catch(this.onError);
	};

	@action
	private onError = (error: AxiosError<IApiResponse>) => {
		this._error = extractErrorMessage(error);
	};
}
