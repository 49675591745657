import {AxiosError} from "axios";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IApiResponse} from "data/services/http";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IContest} from "data/types/contest";
import type {IModalTeamSaveData} from "data/types/modals";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface IModalTeamOutcomeSaveController extends ViewController {
	confirm: () => void;
	close: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;

	get isFlip(): boolean;

	get isLoading(): boolean;

	get swapId(): number | undefined;

	get swapCardName(): string;

	get questionIds(): number[];

	get position(): number;

	get contest(): IContest | undefined;
}

@injectable()
export class ModalTeamOutcomeSaveController implements IModalTeamOutcomeSaveController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.TEAM_SAVE_OUTCOME;
	}

	get isFlip(): boolean {
		return Boolean(this.swapId);
	}

	get position(): number {
		return this.lineup.findIndex((id) => id === this.swapId);
	}

	get swapId(): number | undefined {
		return this.modalData?.swapId;
	}

	public get swapCardName(): string {
		const question = this.contest?.questions?.find(
			(question) => question.questionId === this.swapId
		);
		const text = question?.text ?? "--";
		return this.i18n.t(text, text);
	}

	get modalData() {
		return this._modalsStore.modalContent as IModalTeamSaveData;
	}

	public get questionIds(): number[] {
		return this._answersStore.lineup;
	}

	public get isLoading(): boolean {
		return this._answersStore.isLoading;
	}

	get contest() {
		return this._contestsStore.selectedContest;
	}

	private get lineup() {
		return this._answersStore.lineup;
	}

	private get user() {
		return this._userStore.user;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	public confirm = () => {
		if (!this.contest) {
			return;
		}
		this._answersStore
			.saveAnswers(this.user?.id, this.contest.id, {swap: this.swapId || null})
			.then(() => this.close())
			.catch(this.onError);
	};

	public close = () => {
		this._modalsStore.hideModal();
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showErrorModal(error);
	};
}
