import styled from "@emotion/styled";
import {Tab, Tabs, useMediaQuery} from "@mui/material";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {observer} from "mobx-react";
import React from "react";
import {useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import {INavigationController} from "views/components/navigation/navigation.controller";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	a {
		color: var(--primary-default);
		text-align: center;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		position: relative;
		text-transform: uppercase;
		transition: 0.25s;
		padding-left: 24px;
		padding-right: 24px;
		white-space: nowrap;

		&:active,
		&:hover,
		&:focus,
		&.Mui-selected,
		&.active {
			color: var(--primary-red-700);

			@media screen and (max-width: 750px) {
				color: var(--text-dark);
				font-weight: bold;
			}
		}
	}

	@media screen and (max-width: 750px) {
		width: 100%;
		height: 44px;
		gap: 0;
		border-top: 1px solid var(--neutral-200);
		border-bottom: 1px solid var(--neutral-200);

		.MuiTabs-root {
			width: 100%;
		}

		a {
			color: var(--text-inactive);
			text-transform: capitalize;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
		}
	}
`;

export const Navigation: React.FC = observer(() => {
	const location = useLocation();
	const isMobile = useMediaQuery("(max-width: 750px)");
	const {i18n, tabValue} = useViewController<INavigationController>(
		Bindings.NavigationController,
		{path: location.pathname}
	);

	return (
		<Wrapper>
			<Tabs
				variant={isMobile ? "fullWidth" : "standard"}
				TabIndicatorProps={{
					style: {
						backgroundColor: "var(--primary-red-default)",
						transform: "translateY(-2px)",
					},
				}}
				value={tabValue}
				aria-label="Navigation Tabs">
				<Tab
					value="home"
					component={NavLink}
					to="/"
					label={i18n.t("header.navigation.home", "My Packs")}
				/>
				<Tab
					value="rankings"
					component={NavLink}
					to="/rankings"
					label={i18n.t("header.navigation.leaderboard", "Leaderboard")}
				/>
				<Tab
					value="help"
					component={NavLink}
					to="/help"
					label={i18n.t("header.navigation.help", "Help")}
				/>
			</Tabs>
		</Wrapper>
	);
});
