/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			defaultProps: {
				color: "primary",
				variant: "contained",
			},
			styleOverrides: {
				root: {
					fontWeight: "700",
					fontSize: "16px",
					borderRadius: "28px",
					minHeight: "48px",
					textTransform: "capitalize",
					border: "1px solid var(--primary-default)",
					":hover": {
						":disabled": {
							boxShadow: "none",
						},
					},
					":disabled": {
						pointerEvents: "none",
						cursor: "default",
					},
				},
				containedPrimary: {
					background: "var(--primary-default)",
					borderColor: "var(--primary-default)",
					color: "#FFF",
					":hover": {
						background: "var(--primary-darken)",
						borderColor: "var(--primary-darken)",
					},
					":disabled": {
						background: "var(--neutral-100)",
						borderColor: "var(--neutral-100)",
						color: "var(--neutral-400)",
					},
				},
				outlinedPrimary: {
					background: "var(--neutral-0)",
					borderColor: "var(--primary-default)",
					color: "var(--primary-default)",
					":hover": {
						background: "var(--primary-50)",
						borderColor: "var(--primary-darken)",
						color: "var(--primary-default)",
					},
					":disabled": {
						background: "var(--neutral-0)",
						borderColor: "var(--neutral-400)",
						color: "var(--neutral-400)",
					},
				},
				containedSecondary: {
					color: "#018A8A",
					":hover": {
						background: "#FFF",
					},
				},
				containedError: {
					background: "red",
					color: "#FFF",
				},
			},
			variants: [
				{
					props: {className: "icon-white"},
					style: {
						background: "transparent",
						color: "var(--neutral-0)",
						borderColor: "transparent",
						":hover": {
							background: "var(--neutral-100)",
							borderColor: "transparent",
						},
					},
				},
				{
					props: {className: "icon"},
					style: {
						boxShadow: "none",
						minWidth: "24px",
						minHeight: "24px",
						width: "24px",
						height: "24px",
						padding: "0",
						margin: "0",

						background: "transparent",
						color: "var(--primary-default)",
						borderColor: "transparent",
						":hover": {
							background: "transparent",
							color: "var(--primary-darken)",
							borderColor: "transparent",
						},
					},
				},
			],
		},
	},
};
