import {Bindings} from "data/constants/bindings";
import {TournamentStatus} from "data/enums";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IPlayerCardsCommonEntity} from "data/types/common";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

export interface IPlayerCardResultController extends ViewController<IPlayerCardsCommonEntity> {
	get i18n(): ILocalizationStore;

	get points(): string;

	get contestStatus(): string;

	get player(): IPlayer | undefined;

	get contest(): IContest | undefined;
}

@injectable()
export class PlayerCardResultController implements IPlayerCardResultController {
	@observable private _position: number = Number.MAX_SAFE_INTEGER;
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore
	) {
		makeAutoObservable(this);
	}

	get player(): IPlayer | undefined {
		const playerId = this.lineup[this._position];
		return this._playersStore.getPlayerById(playerId);
	}
	get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}

	private get lineup() {
		return this._answersStore.lineup;
	}

	dispose(): void {
		return;
	}

	get contestStatus(): string {
		if (this.tournament?.status === TournamentStatus.Scheduled) {
			return "";
		}
		return this.contest?.status || "";
	}

	private get tournament() {
		if (!this.contest) {
			return;
		}
		return this._tournamentsStore.getTournamentById(this.contest.tournamentId);
	}

	get points(): string {
		if (!this.player) {
			return "0";
		}
		return (
			this._contestsStore.getPointsPerContestEntity(this._contestId, this.player?.id) || "0"
		);
	}

	init(param: IPlayerCardsCommonEntity): void {
		this._position = param.position;
		this._contestId = param.contestId;
	}

	onChange(param: IPlayerCardsCommonEntity): void {
		this._position = param.position;
		this._contestId = param.contestId;
	}
}
