import {Bindings} from "data/constants/bindings";
import {ShareType, SocialNetwork} from "data/enums";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {share} from "data/utils";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import React from "react";

export interface ISharingController extends ViewController {
	shareAction: (socialNetwork: SocialNetwork) => void;

	showShareDialog: (event: React.SyntheticEvent<HTMLButtonElement>) => void;

	closeShareDialog: () => void;

	shareViaFB: () => void;

	shareViaTW: () => void;

	get downloadImageUrl(): string;

	get i18n(): ILocalizationStore;

	get anchorEl(): HTMLButtonElement | null;

	get isMobileShareVisible(): boolean;

	get isDialogOpen(): boolean;

	get isSharingLoading(): boolean;
}

@injectable()
export class SharingController implements ISharingController {
	@observable private _isSharingLoading: boolean = false;
	@observable private _downloadImageUrl: string = "";
	@observable private _anchorEl: HTMLButtonElement | null = null;
	@observable private _isDialogOpen: boolean = false;
	@observable private _isNarrowScreen: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	public get isSharingLoading(): boolean {
		return this._isSharingLoading;
	}

	public get anchorEl(): HTMLButtonElement | null {
		return this._anchorEl;
	}

	public get isDialogOpen(): boolean {
		return this._isDialogOpen;
	}

	public get isMobileShareVisible() {
		return "share" in navigator && this._isNarrowScreen;
	}

	public get downloadImageUrl(): string {
		return this._downloadImageUrl;
	}

	private get narrowScreenWatcher() {
		return window.matchMedia("(max-width: 960px)");
	}

	private get contest(): IContest | undefined {
		return this._contestsStore.selectedContest;
	}

	private get user(): IUser | undefined {
		return this._userStore.user;
	}

	public shareViaFB = () => {
		this.shareAction(SocialNetwork.Facebook);
	};

	public shareViaTW = () => {
		this.shareAction(SocialNetwork.Twitter);
	};

	@action
	public setAnchorEl = (event: React.SyntheticEvent<HTMLButtonElement> | null) => {
		this._anchorEl = event?.currentTarget || null;
	};

	public shareAction = (socialNetwork: SocialNetwork) => {
		if (!this.contest || !this.user) {
			return;
		}
		share(
			{
				socialNetwork,
				type: ShareType.General,
				message: this.i18n.t("sharing.copy.message", ""),
				data: {
					userId: this.user.id,
					contestId: this.contest.id,
				},
			},
			this.user?.id
		);
	};

	@action
	public showShareDialog = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		this.setAnchorEl(event);
		void this.downloadImageForSharing();
		this._isDialogOpen = true;
	};

	@action
	public closeShareDialog = () => {
		this.setAnchorEl(null);
		this._isDialogOpen = false;
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		this.updateNarrowScreenFlag();
		this.narrowScreenWatcher.addEventListener("change", this.updateNarrowScreenFlag);
	}

	onChange(param: void): void {
		return;
	}

	@action
	private updateNarrowScreenFlag = () => {
		this._isNarrowScreen = this.narrowScreenWatcher.matches;
	};

	@action
	private async downloadImageForSharing() {
		try {
			if (!this.contest?.id) {
				return;
			}
			this._isSharingLoading = true;
			const {data} = await this._answersStore.generateSharingImage(this.contest.id);

			runInAction(() => {
				this._downloadImageUrl = data.success.image;
			});
		} finally {
			this._isSharingLoading = false;
		}
	}
}
