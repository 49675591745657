import styled from "@emotion/styled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {Button, Container, useMediaQuery} from "@mui/material";
import {ReactComponent as Logo} from "assets/img/logos/logo-game.svg";
import {ReactComponent as SponsorLogo} from "assets/img/logos/logo-sponsor.svg";
import {observer} from "mobx-react";
import React from "react";
import {NavLink} from "react-router-dom";
import {Navigation} from "views/components/navigation/navigation.component";

const Wrapper = styled.header`
	padding: 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 72px;

	@media screen and (max-width: 750px) {
		height: 60px;
		padding: 16px;
	}
`;

const SLogo = styled(Logo)`
	width: 150px;
	height: 100%;
	padding-top: 6px;
`;

const AccountBlock = styled.div`
	width: 120px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 8px;

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 6px;
	}
`;

const HeaderElement = styled.header`
	height: 72px;
	background: var(--neutral-0);

	@media screen and (max-width: 750px) {
		height: 60px;
	}
`;

const SMobileLogo = styled(Logo)``;
const SSponsorLogo = styled(SponsorLogo)``;

export const Header: React.FC = observer(() => {
	const isMobile = useMediaQuery("(max-width: 750px)");

	if (isMobile) {
		return (
			<React.Fragment>
				<HeaderElement>
					<Wrapper>
						<NavLink to="/">
							<SMobileLogo />
						</NavLink>

						<AccountBlock>
							<NavLink to="/my-account">
								<SSponsorLogo />
								<Button className="icon">
									<AccountCircleIcon />
								</Button>
							</NavLink>
						</AccountBlock>
					</Wrapper>
				</HeaderElement>
				<Navigation />
			</React.Fragment>
		);
	}
	return (
		<HeaderElement>
			<Container>
				<Wrapper>
					<NavLink to="/">
						<SLogo />
					</NavLink>
					<Navigation />

					<AccountBlock>
						<NavLink to="/my-account">
							<SSponsorLogo />
							<Button className="icon">
								<AccountCircleIcon />
							</Button>
						</NavLink>
					</AccountBlock>
				</Wrapper>
			</Container>
		</HeaderElement>
	);
});
