import {AxiosError} from "axios";
import {IS_SSO_SKIP} from "data/constants";
import {Bindings} from "data/constants/bindings";
import {ModalType, Platform, PostMessageType} from "data/enums";
import type {IApiResponse} from "data/services/http";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {ISettingsStore} from "data/stores/settings/settings.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IUserStore} from "data/stores/user/user.store";
import {IIframeAuthData} from "data/types/iframe";
import {ViewController} from "data/types/structure";
import {Platforming} from "data/utils/platforming";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";

export interface IBootstrapController extends ViewController {
	get isReady(): boolean;
}

@injectable()
export class BootstrapController implements IBootstrapController {
	@observable private _isReady = false;
	@observable private _isSettingsReady = false;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore
	) {
		makeAutoObservable(this);
	}

	get isReady(): boolean {
		return this._isReady && this._isSettingsReady;
	}

	init() {
		this.fetchData();
		this.receivePostMessageFromSSO();
	}

	private fetchData() {
		this._playersStore.fetchPlayers().catch(this.onError);
		this._contestsStore.fetchContests().catch(this.onError);
		this._tournamentsStore.fetchTournaments().catch(this.onError);
		this._settingsStore.fetchSettings().finally(() => {
			runInAction(() => {
				this._isSettingsReady = true;
			});
		});
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showErrorModal(error);
	};

	private receivePostMessageFromSSO() {
		window.parent.postMessage(JSON.stringify({type: PostMessageType.IframeReady}), "*");

		window.addEventListener("message", (event) => {
			try {
				const data = JSON.parse(String(event.data) || "") as IIframeAuthData;

				if (data.type === PostMessageType.Auth && data.authToken) {
					this._userStore.token = data.authToken;
					this._userStore
						.check_token(this._userStore.token)
						.then(this.checkToken)
						.catch(() => void this._userStore.logout());

					Platforming.platform = data.platform || Platform.Web;
				}
			} catch (e) {
				console.log(e);
			}
		});

		if (IS_SSO_SKIP || window.location.search.toLowerCase().includes("skipsso=true")) {
			const urlToken = new URLSearchParams(window.location.search).get("token");
			if (urlToken) {
				this.authWithMockToken(urlToken);
			}
			this._isReady = true;
		}
	}

	private checkToken = (ssoId: string) => {
		const user = this._userStore.user;
		if (!user || !user.ssoId || user.ssoId !== ssoId) {
			this.forceLogin();
			return;
		}
		this.login();
	};

	private login() {
		if (this._userStore.isAuthorized) {
			this._isReady = true;
			return;
		}

		this.forceLogin();
	}

	private forceLogin() {
		this._userStore
			.login(this._userStore.token)
			.then(() => {
				runInAction(() => {
					this._isReady = true;
				});
			})
			.catch((error: AxiosError<IApiResponse>) => {
				if (error.response?.status === 400) {
					this._modalsStore.showModal(ModalType.SECONDARY_REGISTRATION);

					runInAction(() => {
						this._isReady = true;
					});
				}
			});
	}

	private authWithMockToken(token: string) {
		if (this._userStore.isAuthorized) {
			return;
		}

		this._userStore.token = token;
		this._userStore
			.check_token(this._userStore.token)
			.then(() => this.login())
			.catch(() => void this._userStore.logout());

		Platforming.platform = Platform.Web;
	}
}
