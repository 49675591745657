import {IUserWithXSID} from "data/stores/user/user.store";
import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface IRegisterPayloadBase {
	displayName: string;
	isNotificationsEnabled: boolean;
}

export interface IRegisterPayload extends IRegisterPayloadBase {
	token: string;
}

type TLoginResponse = IApiResponse<{user: IUserWithXSID}>;
type TTokenCheckResponse = IApiResponse<{ssoId: string}>;

export interface IAuthApiProvider {
	check_token: (token: string) => Promise<AxiosResponse<TTokenCheckResponse>>;
	login: (token: string) => Promise<AxiosResponse<TLoginResponse>>;
	register: (payload: IRegisterPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	public check_token(token: string): Promise<AxiosResponse<TTokenCheckResponse>> {
		return this._http.get(`auth/check-jwt/${token}`);
	}

	public login(token: string): Promise<AxiosResponse<TLoginResponse>> {
		return this._http.post(`auth/login/${token}`);
	}

	public logout(): Promise<AxiosResponse> {
		return this._http.post("auth/logout");
	}

	public register(payload: IRegisterPayload): Promise<AxiosResponse<TLoginResponse>> {
		const {token, ...rest} = payload;
		return this._http.post(`auth/register/${token}`, rest);
	}
}
