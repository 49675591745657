import {Bindings} from "data/constants/bindings";
import {ContestStatus} from "data/enums";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {IContest} from "data/types/contest";
import {inject, injectable} from "inversify";
import {last} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";

export interface IContestsStore {
	get list(): IContest[];

	get completeContests(): IContest[];

	get activeContest(): IContest | undefined;

	get liveContest(): IContest | undefined;

	get selectedContest(): IContest | undefined;

	set selectedContest(value: IContest | undefined);

	fetchContests(): Promise<void>;

	getContestById(contestId: number): IContest | undefined;

	getPointsPerContestEntity(contestId: number, entityId: number): string | undefined;
}

@injectable()
export class ContestsStore implements IContestsStore {
	@observable private _list: IContest[] = [];
	@observable private _selectedContest: IContest | undefined;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	public get selectedContest(): IContest | undefined {
		return this._selectedContest;
	}

	public set selectedContest(value: IContest | undefined) {
		this._selectedContest = value;
	}

	get activeContest(): IContest | undefined {
		return this.liveContest || this.nearestSchedule || this.lastComplete;
	}

	public get list(): IContest[] {
		return this._list;
	}

	public get completeContests(): IContest[] {
		return this._list.filter((e) => e.status === ContestStatus.Complete);
	}

	get liveContest(): IContest | undefined {
		return this.list.find((e) => e.status === ContestStatus.Playing);
	}

	private get nearestSchedule(): IContest | undefined {
		return this.list.find((e) => e.status === ContestStatus.Scheduled);
	}

	private get lastComplete(): IContest | undefined {
		const complete = this.list.filter((e) => e.status === ContestStatus.Complete);
		return last(complete);
	}

	public getContestById(contestId: number): IContest | undefined {
		return this._list.find((e) => e.id === contestId);
	}

	public getPointsPerContestEntity(contestId: number, entityId: number): string | undefined {
		const contest = this.getContestById(contestId);
		const entity = contest?.points?.find((entity) => entity.id === entityId);

		return entity?.points;
	}

	@action
	public async fetchContests(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.contests();
			if (!Array.isArray(data)) {
				throw new Error("Error while fetching contests");
			}
			runInAction(() => {
				this._list = data;
			});
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
