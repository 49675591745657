import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";

export interface ISettings {
	inSeason: boolean;
}

export interface ISettingsStore {
	get settings(): ISettings;

	get isLoading(): boolean;

	fetchSettings(): Promise<void>;
}

@injectable()
export class SettingsStore implements ISettingsStore {
	@observable private _settings: ISettings = {
		inSeason: true,
	};
	@observable private _isLoading = true;

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public get settings(): ISettings {
		return this._settings;
	}

	public async fetchSettings() {
		this._isLoading = true;
		try {
			const {data} = await this._jsonProvider.settings();
			runInAction(() => {
				this._settings = data;
			});

			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		} finally {
			runInAction(() => {
				this._isLoading = false;
			});
		}
	}
}
