import styled from "@emotion/styled";

export const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px;
	border-radius: 4px;
	text-align: center;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	button {
		max-width: 300px;
	}
`;

export const ModalCloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	button {
		background: transparent;
		color: var(--primary-default);
		padding: 4px;
		border: none;
		min-width: unset;
		min-height: unset;
		box-shadow: none;

		&:hover,
		&:active,
		&:focus {
			color: var(--neutral-0);
		}
	}
`;

export const ModalIcon = styled.div`
	width: 80px;

	svg {
		width: 100%;
		height: auto;
		fill: #c20000;
	}
`;

export const ModalTitle = styled.h4`
	color: var(--primary-default);
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 800;
	line-height: normal;
`;

export const ModalText = styled.p`
	color: var(--primary-default);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;
