import {Bindings} from "data/constants/bindings";
import {ContestType, ModalType} from "data/enums";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ViewController} from "data/types/structure";
import {CardFlip} from "data/utils/cardFlip";
import {inject, injectable} from "inversify";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";

export interface IGameFooterController extends ViewController {
	saveTeam: () => void;

	get i18n(): ILocalizationStore;

	get isSaveAvailable(): boolean;

	get isFlipCardAnimation(): boolean;

	get isAllCardsOpen(): boolean;
}

@injectable()
export class GameFooterController implements IGameFooterController {
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore
	) {
		makeAutoObservable(this);
	}

	public get isSaveAvailable(): boolean {
		if (this.lineup.length === 0) {
			return false;
		}
		if (this._answersStore.isTeamSaved && this._answersStore.isFirstPackOpen) {
			return true;
		}
		return !this._answersStore.isTeamSaved;
	}

	public get isFlipCardAnimation() {
		return this._answersStore.isFlipCardAnimation;
	}

	public get isAllCardsOpen() {
		if (this.lineup.length === 0) {
			return false;
		}

		return this.lineup.every((entityId) => this._answersStore.openCards.includes(entityId));
	}

	private get isPlayerType() {
		return this._contestsStore.selectedContest?.type === ContestType.Player;
	}

	private get lineup() {
		return this._answersStore.lineup;
	}

	public saveTeam = () => {
		const modalType = this.isPlayerType ? ModalType.TEAM_SAVE : ModalType.TEAM_SAVE_OUTCOME;
		this._modalsStore.showModal(modalType, {swapId: undefined});
	};

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		const subscription = reaction(
			() => this.lineup,
			() => this.checkCards(),
			{fireImmediately: true}
		);
		this._subscriptions$.push(subscription);
	}

	onChange(param: void): void {
		return;
	}

	private checkCards() {
		const contest = this._contestsStore.selectedContest;
		if (!contest) {
			return;
		}

		this._answersStore.openCards = this.lineup.filter((entityId) =>
			CardFlip.getIsFlipped(contest?.id, entityId)
		);
	}
}
