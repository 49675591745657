import {AxiosError} from "axios";
import {Bindings} from "data/constants/bindings";
import {ContestStatus, ModalType} from "data/enums";
import type {IApiResponse} from "data/services/http";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IUserHistoryStore} from "data/stores/user_history/user_history.store";
import type {IContest} from "data/types/contest";
import type {IGameBar} from "data/types/rankings";
import {ViewController} from "data/types/structure";
import {preparePoints} from "data/utils";
import {inject, injectable} from "inversify";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";

export interface IGameBarController extends ViewController {
	openTutorial: () => void;

	get i18n(): ILocalizationStore;

	get contest(): IContest | undefined;

	get contestPoints(): string;

	get contestRank(): string;

	get username(): string;

	get totalPoints(): string;

	get champsPlayed(): string;

	get rank(): string;
}

@injectable()
export class GameBarController implements IGameBarController {
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserHistoryStore) private _userHistoryStore: IUserHistoryStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get username(): string {
		return this._userStore.user?.displayName || "--";
	}

	get contest(): IContest | undefined {
		return this._contestsStore.selectedContest || this._contestsStore.activeContest;
	}

	public get rank(): string {
		if (this.isContestComplete) {
			return String(this.historyStats?.rank ?? "--");
		}
		return String(this.gameBar?.rank ?? "--");
	}

	public get totalPoints(): string {
		if (this.isContestComplete) {
			return preparePoints(this.historyStats?.points);
		}
		return preparePoints(this.gameBar?.points);
	}

	public get contestPoints(): string {
		return preparePoints(this.historyStats?.points);
	}

	public get contestRank(): string {
		return String(this.historyStats?.rank ?? "--");
	}

	public get champsPlayed(): string {
		return String(this.gameBar?.playedGames ?? "--");
	}

	private get isContestComplete(): boolean {
		return this.contest?.status === ContestStatus.Complete;
	}

	private get historyStats() {
		return this._userHistoryStore.userHistory.find((e) => e.contestId === this.contest?.id);
	}

	private get gameBar(): IGameBar | undefined {
		return this._rankingsStore.gameBar;
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		const subscription = reaction(
			() => this._userStore.isAuthorized,
			() => this.fetchGameBar(),
			{fireImmediately: true}
		);

		this._subscriptions$.push(subscription);
	}

	onChange(param: void): void {
		return;
	}

	public openTutorial = () => {
		this._modalsStore.showModal(ModalType.PRE_OPEN_PACK, {skipSave: true});
	};

	private fetchGameBar() {
		if (!this._userStore.isAuthorized) {
			return;
		}

		this._rankingsStore.fetchGameBar().catch(this.onError);
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showErrorModal(error);
	};
}
