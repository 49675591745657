import "reflect-metadata";
import {ThemeProvider} from "@mui/material";
import {StyledEngineProvider} from "@mui/material/styles";
import {BrowserTracing, Event, init, Integrations, Replay} from "@sentry/react";
import "assets/css/reset.css";
import "assets/css/core.css";
import "assets/css/fonts.css";
import {theme} from "assets/theming/theme";
import {BASE_URL, PREPROD_URL, PROD_URL, SENTRY_DSN_URL, UAT_URL} from "data/constants";
import {
	controllers,
	DIContainer,
	providers,
	services,
	stores,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";

import React, {Suspense} from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {RootRoutes, SecretGateRoutes} from "routes";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {ModalConfirm} from "views/components/modals/modal_confirm/modal_confirm.component";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {ModalSecondaryRegistration} from "views/components/modals/modal_secondary_registration/modal_secondary_registration.component";
import {Notification} from "views/components/notification/notification.component";
import {PagePreloader} from "views/components/preloader";
import {Session} from "views/components/session/session.component";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import reportWebVitals from "./reportWebVitals";

let errorCount = 0;
const MAX_ERRORS = 100;
if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			new BrowserTracing(),
			new Replay(),
			new Integrations.Breadcrumbs({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: ["*.f2p.media.geniussports.com", UAT_URL, PREPROD_URL, PROD_URL],
		ignoreErrors: [
			"Abort due to cancellation of share",
			"AbortError: Share canceled",
			/Network Error/i,
			/Fetch Error/i,
		],
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		beforeSend: (event: Event) => {
			errorCount++;
			if (errorCount > MAX_ERRORS) {
				return null;
			}
			return event;
		},
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

createRoot(root).render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<InjectionProvider container={DIContainer}>
					<Session>
						<Bootstrap>
							<BrowserRouter basename={BASE_URL}>
								<Suspense fallback={<PagePreloader />}>
									{SecretGateController.IS_SECRET_PASSED ? (
										<RootRoutes />
									) : (
										<SecretGateRoutes />
									)}
								</Suspense>
							</BrowserRouter>
							<Notification />
							<ModalError />
							<ModalConfirm />
							<ModalSecondaryRegistration />
						</Bootstrap>
					</Session>
				</InjectionProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
