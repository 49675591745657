export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	LeaguesApiProvider: Symbol("LeaguesApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	AnswersApiProvider: Symbol("AnswersApiProvider"),
	RankingsApiProvider: Symbol("RankingsApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	TournamentsStore: Symbol("TournamentsStore"),
	AnswersStore: Symbol("AnswersStore"),
	ContestsStore: Symbol("ContestsStore"),
	NotificationsStore: Symbol("NotificationsStore"),
	RankingsStore: Symbol("RankingsStore"),
	UserHistoryStore: Symbol("UserHistoryStore"),
	SettingsStore: Symbol("SettingsStore"),
	AuthController: Symbol("AuthController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	LeaguesStore: Symbol("LeaguesStore"),
	MyLeaguesController: Symbol("MyLeaguesController"),
	JoinLeaguesController: Symbol("JoinLeaguesController"),
	CreateLeagueController: Symbol("CreateLeagueController"),
	LeagueController: Symbol("LeagueController"),
	LeagueInviteController: Symbol("LeagueInviteController"),
	LeagueSettingsController: Symbol("LeagueSettingsController"),
	LeagueAboutController: Symbol("LeagueAboutController"),
	LeagueInviteFormController: Symbol("LeagueInviteFormController"),
	ModalsStore: Symbol("ModalsStore"),
	ModalErrorController: Symbol("ModalErrorController"),
	ModalConfirmController: Symbol("ModalConfirmController"),
	LocalizationController: Symbol("LocalizationController"),
	LandingBannerController: Symbol("LandingBannerController"),
	NavigationController: Symbol("NavigationController"),
	ChampionshipCurrentController: Symbol("ChampionshipCurrentController"),
	ContestViewController: Symbol("ContestViewController"),
	PlayerCardController: Symbol("PlayerCardController"),
	ModalTeamSaveController: Symbol("ModalTeamSaveController"),
	ModalTeamOutcomeSaveController: Symbol("ModalTeamOutcomeSaveController"),
	GameFooterController: Symbol("GameFooterController"),
	FlipCardUIController: Symbol("FlipCardUIController"),
	FlipCardOutcomeUIController: Symbol("FlipCardOutcomeUIController"),
	ContestStatusController: Symbol("ContestStatusController"),
	ContestPackController: Symbol("ContestPackController"),
	OutcomeCardController: Symbol("OutcomeCardController"),
	PlayerCardResultController: Symbol("PlayerCardResultController"),
	OutcomeCardResultController: Symbol("OutcomeCardResultController"),
	ContestSummaryController: Symbol("ContestSummaryController"),
	OutcomeCardResultPreviewController: Symbol("OutcomeCardResultPreviewController"),
	PlayerCardResultPreviewController: Symbol("PlayerCardResultPreviewController"),
	GameBarController: Symbol("GameBarController"),
	ContestPillController: Symbol("ContestPillController"),
	ModalSecondaryRegistrationController: Symbol("ModalSecondaryRegistrationController"),
	NotificationController: Symbol("NotificationController"),
	RankingsFiltersController: Symbol("RankingsFiltersController"),
	AppHeaderController: Symbol("AppHeaderController"),
	ChampionshipsUpcomingController: Symbol("ChampionshipsUpcomingController"),
	ChampionshipsCompleteController: Symbol("ChampionshipsCompleteController"),
	RankingsTableController: Symbol("RankingsTableController"),
	CardFlipController: Symbol("CardFlipController"),
	SharingController: Symbol("SharingController"),
	OutcomePointsController: Symbol("OutcomePointsController"),
	ModalPackOpenController: Symbol("ModalPackOpenController"),
	ChampionshipCurrentListController: Symbol("ChampionshipCurrentListController"),
};
