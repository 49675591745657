import {AxiosError} from "axios";
import {Bindings} from "data/constants/bindings";
import {ContestType, TournamentStatus} from "data/enums";
import type {IApiResponse} from "data/services/http";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IContestIdParams} from "data/types/common";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {getImageFromBackend} from "data/utils";
import {inject, injectable} from "inversify";
import {type IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import type {NavigateFunction} from "react-router-dom";

interface IParams extends IContestIdParams {
	navigate: NavigateFunction;
}

export interface IContestSummaryController extends ViewController<IParams> {
	getCardMatchesByPosition: (position: number) => string;
	getCardTextByPosition: (position: number) => string;
	getCardPointsByPosition: (position: number) => string;

	get i18n(): ILocalizationStore;

	get contestType(): ContestType;

	get contestStatus(): string;

	get contestImage(): string;

	get lineup(): number[];
}

@injectable()
export class ContestSummaryController implements IContestSummaryController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _navigate: NavigateFunction | undefined;
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	public get contestType(): ContestType {
		return this.contest?.type || ContestType.Player;
	}

	public get contestStatus(): string {
		if (this.tournament?.status === TournamentStatus.Scheduled) {
			return "schedule";
		}

		return this.contest?.status || "";
	}

	public get contestImage(): string {
		return getImageFromBackend(this.contest?.packBackground || "");
	}

	get lineup() {
		return this._answersStore.lineup;
	}

	private get tournament() {
		if (!this.contest) {
			return;
		}
		return this._tournamentsStore.getTournamentById(this.contest?.tournamentId);
	}

	private get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}

	private get isTeamSaved() {
		return this._answersStore.isTeamSaved;
	}

	public getCardTextByPosition = (position: number): string => {
		const entityId = this.lineup[position];
		const player = this._playersStore.getPlayerById(entityId);
		const question = this.contest?.questions?.find(
			(question) => question.questionId === entityId
		);

		switch (this.contestType) {
			case ContestType.Player:
				return player ? `${player.firstName} ${player.lastName}` : "";
			case ContestType.Prop:
				return this.i18n.t(question?.text || "");
			default:
				return "";
		}
	};

	getCardMatchesByPosition = (position: number) => {
		const entityId = this.lineup[position];
		const question = this.contest?.questions?.find(
			(question) => question.questionId === entityId
		);

		if (!question || !question?.matches) {
			return "";
		}

		return `x${question.matches}`;
	};

	getCardPointsByPosition = (position: number): string => {
		const entityId = this.lineup[position];
		return this._contestsStore.getPointsPerContestEntity(this._contestId, entityId) ?? "--";
	};

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: IParams): void {
		this._contestId = param.contestId;
		this._navigate = param.navigate;

		const answersSubscription = reaction(
			() => [this.contest],
			() => this.fetchAnswers(),
			{fireImmediately: true}
		);

		const subscription = reaction(
			() => [this.isTeamSaved, this.contest],
			() => this.checkLineup(),
			{fireImmediately: true}
		);

		this._subscriptions$.push(subscription);
		this._subscriptions$.push(answersSubscription);
	}

	onChange(param: IParams): void {
		this._contestId = param.contestId;
		this._navigate = param.navigate;
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showErrorModal(error);
	};

	private checkLineup() {
		if (this.contest) {
			this._contestsStore.selectedContest = this.contest;
		}

		if (!this.isTeamSaved && this.contest) {
			this._navigate?.(`/contest/${this.contest.id}`);
		}
	}

	private fetchAnswers() {
		if (this.contest) {
			this._answersStore.fetchAnswers(this.contest.id).catch(this.onError);
		}
	}
}
