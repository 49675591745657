import {Bindings} from "data/constants/bindings";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

interface IParams {
	playerId: number | undefined;
	contestId: number;
}
export interface IFlipCardUIController extends ViewController<IParams> {
	get contest(): IContest | undefined;
	get player(): IPlayer | undefined;
}

@injectable()
export class FlipCardUIController implements IFlipCardUIController {
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;
	@observable private _playerId: number = Number.MAX_SAFE_INTEGER;
	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore
	) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._contestId = param.contestId;
		this._playerId = param.playerId || Number.MAX_SAFE_INTEGER;
	}

	onChange(param: IParams): void {
		this._contestId = param.contestId;
		this._playerId = param.playerId || Number.MAX_SAFE_INTEGER;
	}

	public get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}

	public get player(): IPlayer | undefined {
		return this._playersStore.getPlayerById(this._playerId);
	}
}
