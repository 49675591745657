import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import {Button, Modal} from "@mui/material";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {observer} from "mobx-react";
import React from "react";
import {Exist} from "views/components/exist/exist.component";
import {
	ModalCloseBlock,
	ModalContent,
	ModalIcon,
	ModalText,
	ModalTitle,
} from "views/components/modals/common";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";

const CloseButton = styled(Button)`
	max-width: 320px;
	width: 100%;
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);

	return (
		<Modal open={isOpen}>
			<ModalContent>
				<ModalCloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</ModalCloseBlock>
				<ModalIcon>
					<ErrorIcon />
				</ModalIcon>
				<ModalTitle>
					{i18n.t(modalContent?.title ?? "modal.error.title", "Error")}
				</ModalTitle>
				<Exist when={modalContent?.message}>
					<ModalText>{modalContent?.message}</ModalText>
				</Exist>

				<CloseButton onClick={close}>{i18n.t("modal.error.close", "Close")}</CloseButton>
			</ModalContent>
		</Modal>
	);
});
