import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {IModalTeamSaveData, IModalTutorialData} from "data/types/modals";
import {extractErrorMessage} from "data/utils";
import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {ModalType} from "data/enums";

export interface IModalPayload {
	title?: string;
	message: string;
	callback?: (payload?: unknown) => void;
}

export type ModalPayload = IModalPayload | IModalTeamSaveData | IModalTutorialData;
export type TModalArguments = [type: ModalType] | [type: ModalType, content: ModalPayload];

export interface IModalsStore {
	showModal(...args: TModalArguments): void;
	showErrorModal(error: AxiosError<IApiResponse>): void;
	showErrorModalMessage(message: string): void;
	hideModal(): void;
	get modal(): ModalType | null;
	get modalContent(): ModalPayload | null;
}

@injectable()
export class ModalsStore implements IModalsStore {
	@observable _visibleModal: ModalType | null = null;
	@observable _modalContent: ModalPayload | null = null;

	get modal() {
		return this._visibleModal;
	}

	get modalContent() {
		return this._modalContent;
	}

	constructor() {
		makeAutoObservable(this);
	}

	@action hideModal(): void {
		this._visibleModal = null;
		this._modalContent = null;
	}

	@action
	public showModal(...args: TModalArguments): void {
		const [modalType, content = null] = args;

		this._visibleModal = modalType;
		this._modalContent = content;
	}

	@action
	public showErrorModal(error: AxiosError<IApiResponse>) {
		this.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	}

	@action
	public showErrorModalMessage(message: string) {
		this.showModal(ModalType.ERROR, {message});
	}
}
