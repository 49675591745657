import {ISettings} from "data/stores/settings/settings.store";
import {ITournament} from "data/stores/tournaments/tournaments.store";
import {IContest} from "data/types/contest";
import {inject, injectable} from "inversify";
import type {IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {
	IArticles,
	ICategories,
	ISections,
} from "data/stores/static_content/static_content.store";
import type {IPlayer} from "data/stores/players/players.store";
import type {ICountry} from "data/stores/countries/countries.store";
import type {IRound} from "data/stores/rounds/rounds.store";
import type {ISquad} from "data/stores/squads/squads.store";
import type {IChecksums} from "data/stores/checksum/checksum.store";
import {Language, RoundStatus} from "data/enums";

export interface IJSONProvider {
	countries(): Promise<AxiosResponse<ICountry[]>>;
	settings(): Promise<AxiosResponse<ISettings>>;
	rounds(): Promise<AxiosResponse<IRound[]>>;
	players(): Promise<AxiosResponse<IPlayer[]>>;
	squads(): Promise<AxiosResponse<ISquad[]>>;
	checksums(): Promise<AxiosResponse<IChecksums>>;
	contests(): Promise<AxiosResponse<IContest[]>>;
	tournaments(): Promise<AxiosResponse<ITournament[]>>;

	translations(locale: string): Promise<AxiosResponse<Record<string, unknown>>>;
	helpCategories(locale: string): Promise<AxiosResponse<ICategories>>;
	helpSections(locale: string): Promise<AxiosResponse<ISections>>;
	helpArticles(locale: string): Promise<AxiosResponse<IArticles>>;
}

@injectable()
export class JSONProvider implements IJSONProvider {
	constructor(
		@inject(Bindings.JsonHTTPClient) private _jsonClient: IHttpClientService,
		@inject(Bindings.ContentJsonHTTPClient) private _contentJsonClient: IHttpClientService
	) {}

	settings = () => this._jsonClient.get<ISettings>("fantasy/settings.json");
	countries = () => this._jsonClient.get<ICountry[]>("countries.json");
	contests = () => this._jsonClient.get<IContest[]>("fantasy/contests.json");
	tournaments = () => this._jsonClient.get<ITournament[]>("fantasy/tournaments.json");
	rounds = () => {
		// TODO Uncomment real request and remove fake data
		// return this._jsonClient.get<IRound[]>("rounds.json")

		const dates = {
			startDate: new Date().toISOString(),
			endDate: new Date().toISOString(),
		};

		return Promise.resolve({
			data: [
				{
					id: 1,
					status: RoundStatus.Complete,
					...dates,
				},
				{
					id: 2,
					status: RoundStatus.Playing,
					...dates,
				},
				{
					id: 3,
					status: RoundStatus.Scheduled,
					...dates,
				},
				{
					id: 4,
					status: RoundStatus.Scheduled,
					...dates,
				},
			],
		}) as Promise<AxiosResponse<IRound[]>>;
	};
	players = () => this._jsonClient.get<IPlayer[]>("fantasy/players.json");
	squads = () => this._jsonClient.get<ISquad[]>("squads.json");
	checksums = () => this._jsonClient.get<IChecksums>("fantasy/checksums.json");
	translations = (locale: string) =>
		this._jsonClient.get<Record<string, unknown>>(`fantasy/loco/${locale}.json`);

	helpCategories = (locale: Language) =>
		this._contentJsonClient.get<ICategories>(`${locale}/categories.json`);
	helpSections = (locale: Language) =>
		this._contentJsonClient.get<ISections>(`${locale}/sections.json`);
	helpArticles = (locale: Language) =>
		this._contentJsonClient.get<IArticles>(`${locale}/articles.json`);
}
