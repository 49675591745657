import {Bindings} from "data/constants/bindings";
import type {
	INotification,
	INotificationsStore,
} from "data/stores/notifications/notifications.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface INotificationController extends ViewController {
	get notifications(): INotification[];
}

@injectable()
export class NotificationController implements INotificationController {
	constructor(
		@inject(Bindings.NotificationsStore) private _notificationsStore: INotificationsStore
	) {
		makeAutoObservable(this);
	}

	get notifications(): INotification[] {
		return this._notificationsStore.notifications;
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}
}
