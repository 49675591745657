import {Bindings} from "data/constants/bindings";
import {ContestStatus} from "data/enums";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITournament, ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {noop} from "lodash";
import {DateTime} from "luxon";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";

interface IParams {
	contestId: number;
}

export interface IChampionshipCurrentController extends ViewController<IParams> {
	get i18n(): ILocalizationStore;

	get contest(): IContest | undefined;

	get isPackOpen(): boolean;

	get isOpenDisabled(): boolean;

	get isTournamentEnded(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class ChampionshipCurrentController implements IChampionshipCurrentController {
	@observable private _isPackOpen: boolean = false;
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isTournamentEnded(): boolean {
		if (!this.tournament) {
			return true;
		}

		const tournamentEndDate = DateTime.fromISO(this.tournament.dateEnd);
		const now = DateTime.now().setZone("America/New_York");
		const isSameDay = now.hasSame(tournamentEndDate, "day");
		const isAfterTwelve = now.get("hour") >= 12;

		return isSameDay && isAfterTwelve;
	}

	get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}

	public get isPackOpen(): boolean {
		return this._isPackOpen;
	}

	public get isLoading(): boolean {
		return this._answersStore.isLoading;
	}

	public get isOpenDisabled(): boolean {
		if (this.isPackOpen) {
			return false;
		}

		return this.contest?.status === ContestStatus.Scheduled || this.isTournamentEnded;
	}

	private get tournament(): ITournament | undefined {
		if (!this.contest) {
			return;
		}

		return this._tournamentsStore.getTournamentById(this.contest.tournamentId);
	}

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: IParams): void {
		this._contestId = param.contestId;
		const subscription = reaction(
			() => this.contest,
			() => this.checkContest(),
			{fireImmediately: true}
		);

		this._subscriptions$.push(subscription);
	}

	onChange(param: IParams): void {
		this._contestId = param.contestId;
	}

	private checkContest() {
		if (!this.contest) {
			return;
		}
		if (this._userStore.isAuthorized) {
			this._answersStore
				.fetchAnswersNoStore(this.contest.id)
				.then((response) => {
					console.log(response.lineup);
					this._isPackOpen = response.lineup.length > 0;
				})
				.catch(noop);
		}
	}
}
