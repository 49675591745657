import {Bindings} from "data/constants/bindings";
import {ContestType} from "data/enums";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {getImageFromBackend} from "data/utils";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

interface IParams {
	contestId: number;
}

export interface IContestPackController extends ViewController<IParams> {
	get contest(): IContest | undefined;
	get contestLogo(): string;
	get isPlayerContest(): boolean;
}

@injectable()
export class ContestPackController implements IContestPackController {
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;
	constructor(@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore) {
		makeAutoObservable(this);
	}

	get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}
	get contestLogo(): string {
		return getImageFromBackend(this.contest?.logo || "");
	}
	get isPlayerContest(): boolean {
		return this.contest?.type === ContestType.Player;
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._contestId = param.contestId;
	}

	onChange(param: IParams): void {
		this._contestId = param.contestId;
	}
}
