import {Bindings} from "data/constants/bindings";
import {TournamentStatus} from "data/enums";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";

export interface ITournament {
	id: string;
	name: string;
	status: TournamentStatus;
	dateStart: string;
	dateEnd: string;
	players: number[];
}

export interface ITournamentsStore {
	get list(): ITournament[];

	fetchTournaments(): Promise<void>;

	getTournamentById(id: string | number): ITournament | undefined;
}

@injectable()
export class TournamentsStore implements ITournamentsStore {
	@observable private _list: ITournament[] = [];

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	getTournamentById(id: string | number): ITournament | undefined {
		return this._list.find((e) => e.id === String(id));
	}

	public get list(): ITournament[] {
		return this._list;
	}

	public async fetchTournaments(): Promise<void> {
		try {
			const {data} = await this._jsonProvider.tournaments();
			if (!Array.isArray(data)) {
				throw new Error("Error while loading tournaments");
			}

			runInAction(() => {
				this._list = data;
			});
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
