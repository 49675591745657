import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {IAnswerPayload, IAnswersResponse, IShareImageResponse} from "data/types/contest";
import {inject, injectable} from "inversify";

type TAnswersResponse = IApiResponse<IAnswersResponse>;
export type TShareImageResponse = IApiResponse<IShareImageResponse>;

export interface IAnswersApiProvider {
	saveAnswers: (
		contestId: number,
		payload: IAnswerPayload
	) => Promise<AxiosResponse<TAnswersResponse>>;
	fetchAnswers: (contestId: number) => Promise<AxiosResponse<TAnswersResponse>>;
	generateCards: (contestId: number) => Promise<AxiosResponse<TAnswersResponse>>;
	generateSharingImage: (contestId: number) => Promise<AxiosResponse<TShareImageResponse>>;
}

@injectable()
export class AnswersApiProvider implements IAnswersApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	public generateCards = (contestId: number) =>
		this._http.post<TAnswersResponse>(`fantasy/contest/answer/${contestId}`);

	public saveAnswers = (contestId: number, payload: IAnswerPayload) =>
		this._http.post<TAnswersResponse>(`fantasy/contest/answer/${contestId}`, payload);

	public fetchAnswers = (contestId: number) =>
		this._http.get<TAnswersResponse>(`fantasy/contest/answer/${contestId}`);

	public generateSharingImage = (contestId: number) =>
		this._http.get<TShareImageResponse>(`fantasy/sharing-image/${contestId}`);
}
