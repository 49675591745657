import {IS_OFF_SEASON} from "data/constants";
import {Bindings} from "data/constants/bindings";
import {ContestStatus} from "data/enums";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISettingsStore} from "data/stores/settings/settings.store";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface IChampionshipCurrentListController extends ViewController {
	get i18n(): ILocalizationStore;

	get contests(): IContest[];
	get isOffSeason(): boolean;
}

@injectable()
export class ChampionshipCurrentListController implements IChampionshipCurrentListController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore
	) {
		makeAutoObservable(this);
	}

	public get isOffSeason(): boolean {
		if (IS_OFF_SEASON) {
			return true;
		}
		return !this._settingsStore.settings?.inSeason;
	}

	get contests(): IContest[] {
		const playing = this._contestsStore.list.filter(
			(contest) => contest.status === ContestStatus.Playing
		);
		const nearestSchedule = this._contestsStore.list.find(
			(contest) => contest.status === ContestStatus.Scheduled
		);
		if (!playing.length) {
			return nearestSchedule ? [nearestSchedule] : [];
		}
		return [...playing];
	}

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}
}
