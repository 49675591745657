import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

interface IParams {
	path: string;
}
export interface INavigationController extends ViewController<IParams> {
	get i18n(): ILocalizationStore;
	get tabValue(): string;
}

@injectable()
export class NavigationController implements INavigationController {
	@observable private _path: string = "";
	constructor(@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	dispose(): void {
		return;
	}

	public get tabValue(): string {
		if (this._path.includes("/help")) {
			return "help";
		}
		if (this._path.includes("/rankings")) {
			return "rankings";
		}
		return "home";
	}

	init(param: IParams): void {
		this._path = param.path;
	}

	onChange(param: IParams): void {
		this._path = param.path;
	}
}
