import {IS_OFF_SEASON} from "data/constants";
import {Bindings} from "data/constants/bindings";
import {ContestStatus} from "data/enums";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISettingsStore} from "data/stores/settings/settings.store";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface IChampionshipsUpcomingController extends ViewController {
	get i18n(): ILocalizationStore;
	get contests(): IContest[];
	get isOffSeason(): boolean;
}

@injectable()
export class ChampionshipsUpcomingController implements IChampionshipsUpcomingController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.SettingsStore) private _settingsStore: ISettingsStore
	) {
		makeAutoObservable(this);
	}

	public get isOffSeason(): boolean {
		if (IS_OFF_SEASON) {
			return true;
		}
		return !this._settingsStore.settings?.inSeason;
	}

	public get contests(): IContest[] {
		const contests = this._contestsStore.list.filter((contest) =>
			[ContestStatus.Playing, ContestStatus.Scheduled].includes(contest.status)
		);
		return contests.filter((contest) => contest.id !== this.currentChampionship?.id);
	}

	private get currentChampionship() {
		return this._contestsStore.list.find((contest) =>
			[ContestStatus.Playing, ContestStatus.Scheduled].includes(contest.status)
		);
	}
}
