import {Bindings} from "data/constants/bindings";
import {ContestStatus, ModalType, TournamentStatus} from "data/enums";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

interface IParams {
	contestId: number;
}

export interface IContestStatusController extends ViewController<IParams> {
	openTutorial: () => void;

	get i18n(): ILocalizationStore;

	get contest(): IContest | undefined;

	get isFlipByStatusAvailable(): boolean;
}

@injectable()
export class ContestStatusController implements IContestStatusController {
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;
	constructor(
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore
	) {
		makeAutoObservable(this);
	}

	get isFlipByStatusAvailable(): boolean {
		return (
			this.tournament?.status === TournamentStatus.Scheduled &&
			this.contest?.status === ContestStatus.Playing
		);
	}

	get contest() {
		return this._contestsStore.getContestById(this._contestId);
	}

	private get tournament() {
		if (!this.contest) {
			return;
		}
		return this._tournamentsStore.getTournamentById(this.contest.tournamentId);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._contestId = param.contestId;
	}

	onChange(param: IParams): void {
		this._contestId = param.contestId;
	}

	public openTutorial = () => {
		this._modalsStore.showModal(ModalType.PRE_OPEN_PACK, {skipSave: true});
	};
}
