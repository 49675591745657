import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IAuthApiProvider, IRegisterPayloadBase} from "data/providers/api/auth.api.provider";
import type {
	IForgotPasswordPayload,
	IPasswordApiProvider,
	IResetPasswordPayload,
} from "data/providers/api/password.api.provider";
import type {IUpdateUserPayload, IUserApiProvider} from "data/providers/api/user.api.provider";
import {createConnextraScriptTag} from "data/utils/connextra";
import {Platforming} from "data/utils/platforming";
import {SessionUtils} from "data/utils/sessionUtils";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";

export interface IUser {
	id: number;
	email: string;
	displayName: string;
	isNotificationsEnabled: boolean;
	ssoId: string;
}

export interface IUserWithXSID extends IUser {
	sid: string;
}

export interface IUserStore {
	get user(): IUser | undefined;

	get isAuthorized(): boolean;

	get wasLoggedOut(): boolean;

	get token(): string;

	set token(value: string);

	forgotPassword(payload: IForgotPasswordPayload): Promise<AxiosResponse<void>>;

	resetPassword(payload: IResetPasswordPayload): Promise<AxiosResponse<void>>;

	register(payload: IRegisterPayloadBase): Promise<void>;

	update(payload: IUpdateUserPayload): Promise<void>;

	deactivate(): Promise<void>;

	login(token: string): Promise<void>;

	check_token(token: string): Promise<string>;

	logout(): Promise<void>;

	requestUser(): Promise<void>;
}

@injectable()
export class UserStore implements IUserStore {
	@observable private _token: string = "";
	@observable private _user?: IUser = undefined;
	@observable private _wasLoggedOut = false;

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.PasswordApiProvider) private _passwordApi: IPasswordApiProvider
	) {
		makeAutoObservable(this);
	}

	public get token(): string {
		return this._token;
	}

	public set token(value: string) {
		this._token = value;
	}

	get isAuthorized() {
		return Boolean(this.user);
	}

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get user() {
		return this._user;
	}

	@action
	async requestUser() {
		const response = await this._userApi.user();
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async check_token(token: string) {
		try {
			const {data} = await this._authApi.check_token(token);

			return data.success.ssoId;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	@action
	async login(token: string) {
		const response = await this._authApi.login(token);
		const data = response.data.success;

		const {sid, ...rest} = data.user;
		createConnextraScriptTag(`f2p_fantasyflip_loggedin?AccountID=${data.user.id}`);

		if (sid) {
			SessionUtils.save(sid);
		}

		runInAction(() => {
			this._user = rest;
			this._wasLoggedOut = false;
		});
	}

	@action
	async register(payload: IRegisterPayloadBase) {
		const tokenPayload = {
			...payload,
			token: this.token,
		};
		const response = await this._authApi.register(tokenPayload);
		const data = response.data.success;

		const {sid, ...rest} = data.user;
		createConnextraScriptTag(`f2p_fantasyflip_regconfirm?AccountID=${data.user.id}`);

		if (sid) {
			SessionUtils.save(sid);
		}

		runInAction(() => {
			this._user = rest;
			this._wasLoggedOut = false;
		});
	}

	@action
	async update(payload: IUpdateUserPayload) {
		const response = await this._userApi.update(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});

		const type = Platforming.REDIRECT();
		window.parent?.postMessage(JSON.stringify({type}), "*");
		SessionUtils.clear();
	}

	@action
	async deactivate() {
		await this._userApi.deactivateAccount();

		runInAction(() => {
			this._user = undefined;
			this._wasLoggedOut = true;
		});
	}

	forgotPassword(payload: IForgotPasswordPayload) {
		return this._passwordApi.forgotPassword(payload);
	}

	resetPassword(payload: IResetPasswordPayload) {
		return this._passwordApi.resetPassword(payload);
	}
}
