import type {SelectChangeEvent} from "@mui/material";
import {AxiosError} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse} from "data/services/http";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {last} from "lodash";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";

export interface IRankingsFiltersController extends ViewController {
	onContestSelect: (event: SelectChangeEvent<unknown>) => void;

	get i18n(): ILocalizationStore;

	get contests(): IContest[];

	get selectedContest(): IContest | undefined;
}

@injectable()
export class RankingsFiltersController implements IRankingsFiltersController {
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get selectedContest(): IContest | undefined {
		if (this._rankingsStore.isOverall) {
			return undefined;
		}
		return this._rankingsStore.selectedContest;
	}

	public get contests(): IContest[] {
		return [...this._contestsStore.completeContests, this._contestsStore.liveContest].filter(
			(e) => Boolean(e?.id)
		) as IContest[];
	}

	private get lastContest(): IContest | undefined {
		const live = this._contestsStore.liveContest;
		const lastComplete = last(this._contestsStore.completeContests);
		return live || lastComplete;
	}

	public onContestSelect = (event: SelectChangeEvent<unknown>) => {
		const contestId = Number(event.target.value);
		const contest = this._contestsStore.getContestById(contestId);

		if (contestId === 0) {
			this._rankingsStore.selectedContest = this.lastContest;
			this._rankingsStore.isOverall = true;
			return;
		}

		if (!contest) {
			return;
		}

		this._rankingsStore.selectedContest = contest;
		this._rankingsStore.isOverall = false;
	};

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		const emptyContestSubscription = reaction(
			() => [this.selectedContest, this.contests],
			() => this.checkFirstContest(),
			{fireImmediately: true}
		);

		const fetchRankingsSubscription = reaction(
			() => this.selectedContest,
			() => this.fetchRankings(),
			{fireImmediately: true}
		);

		this._subscriptions$.push(emptyContestSubscription);
		this._subscriptions$.push(fetchRankingsSubscription);
	}

	onChange(param: void): void {
		return;
	}

	private checkFirstContest() {
		if (this.contests.length > 0 && !this.selectedContest) {
			this._rankingsStore.selectedContest = last(this.contests);
		}
	}

	private fetchRankings() {
		if (this._rankingsStore.isOverall && this.lastContest) {
			this._rankingsStore.fetchRankings(this.lastContest.id).catch(this.onError);
			return;
		}

		if (!this.selectedContest) {
			return;
		}
		this._rankingsStore.fetchRankings(this.selectedContest.id).catch(this.onError);
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showErrorModal(error);
	};
}
