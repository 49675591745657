export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const FLAGS_URL = process.env.REACT_APP_FLAGS_URL || "";
export const CONNEXTRA_TAG = process.env.REACT_APP_CONNEXTRA_TAG || "";

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\\-!@#$%^&*\\(\\)_+\\|~=`\\{\\}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_APP_ID || false;

export const UAT_URL = "https://usga-fantasy.uat.f2p.media.geniussports.com";
export const PREPROD_URL = "https://usga-fantasy.preprod.f2p.media.geniussports.com/";
export const PROD_URL = "https://fantasy.usga.org/";
export const IS_SSO_SKIP = process.env.REACT_APP_IS_SSO_PASS === "true";
export const OVERALL_LEAGUE_ID = 1;
export const PRE_OPEN_KEY = "usga_p_m_";
export const IS_OFF_SEASON = process.env.REACT_APP_IS_OFF_SEASON === "true";

export const COUNTRY_ISO_CODES = {
	afg: "af",
	ala: "ax",
	alb: "al",
	dza: "dz",
	asm: "as",
	and: "ad",
	ago: "ao",
	aia: "ai",
	ata: "aq",
	atg: "ag",
	arg: "ar",
	arm: "am",
	abw: "aw",
	aus: "au",
	aut: "at",
	aze: "az",
	bhs: "bs",
	bhr: "bh",
	bgd: "bd",
	brb: "bb",
	blr: "by",
	bel: "be",
	blz: "bz",
	ben: "bj",
	bmu: "bm",
	btn: "bt",
	bol: "bo",
	bih: "ba",
	bwa: "bw",
	bvt: "bv",
	bra: "br",
	vgb: "vg",
	iot: "io",
	brn: "bn",
	bgr: "bg",
	bfa: "bf",
	bdi: "bi",
	khm: "kh",
	cmr: "cm",
	can: "ca",
	cpv: "cv",
	cym: "ky",
	caf: "cf",
	tcd: "td",
	chl: "cl",
	chn: "cn",
	hkg: "hk",
	mac: "mo",
	cxr: "cx",
	cck: "cc",
	col: "co",
	com: "km",
	cog: "cg",
	cod: "cd",
	cok: "ck",
	cri: "cr",
	civ: "ci",
	hrv: "hr",
	cub: "cu",
	cyp: "cy",
	cze: "cz",
	dnk: "dk",
	dji: "dj",
	dma: "dm",
	dom: "do",
	ecu: "ec",
	egy: "eg",
	slv: "sv",
	gnq: "gq",
	eri: "er",
	est: "ee",
	eth: "et",
	flk: "fk",
	fro: "fo",
	fji: "fj",
	fin: "fi",
	fra: "fr",
	guf: "gf",
	pyf: "pf",
	atf: "tf",
	gab: "ga",
	gmb: "gm",
	geo: "ge",
	deu: "de",
	gha: "gh",
	gib: "gi",
	grc: "gr",
	grl: "gl",
	grd: "gd",
	glp: "gp",
	gum: "gu",
	gtm: "gt",
	ggy: "gg",
	gin: "gn",
	gnb: "gw",
	guy: "gy",
	hti: "ht",
	hmd: "hm",
	vat: "va",
	hnd: "hn",
	hun: "hu",
	isl: "is",
	ind: "in",
	idn: "id",
	irn: "ir",
	irq: "iq",
	irl: "ie",
	imn: "im",
	isr: "il",
	ita: "it",
	jam: "jm",
	jpn: "jp",
	jey: "je",
	jor: "jo",
	kaz: "kz",
	ken: "ke",
	kir: "ki",
	prk: "kp",
	kor: "kr",
	kwt: "kw",
	kgz: "kg",
	lao: "la",
	lva: "lv",
	lbn: "lb",
	lso: "ls",
	lbr: "lr",
	lby: "ly",
	lie: "li",
	ltu: "lt",
	lux: "lu",
	mkd: "mk",
	mdg: "mg",
	mwi: "mw",
	mys: "my",
	mdv: "mv",
	mli: "ml",
	mlt: "mt",
	mhl: "mh",
	mtq: "mq",
	mrt: "mr",
	mus: "mu",
	myt: "yt",
	mex: "mx",
	fsm: "fm",
	mda: "md",
	mco: "mc",
	mng: "mn",
	mne: "me",
	msr: "ms",
	mar: "ma",
	moz: "mz",
	mmr: "mm",
	nam: "na",
	nru: "nr",
	npl: "np",
	nld: "nl",
	ant: "an",
	ncl: "nc",
	nzl: "nz",
	nic: "ni",
	ner: "ne",
	nga: "ng",
	niu: "nu",
	nfk: "nf",
	mnp: "mp",
	nor: "no",
	omn: "om",
	pak: "pk",
	plw: "pw",
	pse: "ps",
	pan: "pa",
	png: "pg",
	pry: "py",
	per: "pe",
	phl: "ph",
	pcn: "pn",
	pol: "pl",
	prt: "pt",
	pri: "pr",
	qat: "qa",
	reu: "re",
	rou: "ro",
	rus: "ru",
	rwa: "rw",
	blm: "bl",
	shn: "sh",
	kna: "kn",
	lca: "lc",
	maf: "mf",
	spm: "pm",
	vct: "vc",
	wsm: "ws",
	smr: "sm",
	stp: "st",
	sau: "sa",
	sen: "sn",
	srb: "rs",
	syc: "sc",
	sle: "sl",
	sgp: "sg",
	svk: "sk",
	svn: "si",
	slb: "sb",
	som: "so",
	zaf: "za",
	sgs: "gs",
	ssd: "ss",
	esp: "es",
	lka: "lk",
	sdn: "sd",
	sur: "sr",
	sjm: "sj",
	swz: "sz",
	swe: "se",
	che: "ch",
	syr: "sy",
	twn: "tw",
	tjk: "tj",
	tza: "tz",
	tha: "th",
	tls: "tl",
	tgo: "tg",
	tkl: "tk",
	ton: "to",
	tto: "tt",
	tun: "tn",
	tur: "tr",
	tkm: "tm",
	tca: "tc",
	tuv: "tv",
	uga: "ug",
	ukr: "ua",
	are: "ae",
	gbr: "gb",
	usa: "us",
	umi: "um",
	ury: "uy",
	uzb: "uz",
	vut: "vu",
	ven: "ve",
	vnm: "vn",
	vir: "vi",
	wlf: "wf",
	esh: "eh",
	yem: "ye",
	zmb: "zm",
	zwe: "zw",
} as Record<string, string>;
