import {CONNEXTRA_TAG} from "data/constants";

export const createConnextraScriptTag = (tagName: string) => {
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = `https://us.connextra.com/dcs/tagController/tag/${CONNEXTRA_TAG}/${tagName}`;
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
