import {AxiosError} from "axios";
import {Bindings} from "data/constants/bindings";
import {IApiResponse} from "data/services/http";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IUserHistoryStore} from "data/stores/user_history/user_history.store";
import type {IContest} from "data/types/contest";
import type {IContestHistoryRanking} from "data/types/rankings";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";

export interface IChampionshipsCompleteController extends ViewController {
	getIsPackOpen: (contestId: number) => boolean;
	getContestStat: (contestId: number, stat: keyof IContestHistoryRanking) => string;

	get i18n(): ILocalizationStore;

	get contests(): IContest[];

	get isEmpty(): boolean;
}

@injectable()
export class ChampionshipsCompleteController implements IChampionshipsCompleteController {
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.UserHistoryStore) private _userHistoryStore: IUserHistoryStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get contests(): IContest[] {
		return this._contestsStore.completeContests;
	}

	get isEmpty(): boolean {
		return this.contests.length === 0;
	}

	public getContestStat = (contestId: number, stat: keyof IContestHistoryRanking): string => {
		const contest = this._userHistoryStore.userHistory.find((e) => e.contestId === contestId);

		return String(contest?.[stat] ?? "-");
	};

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: void): void {
		const subscription = reaction(
			() => [this.contests, this._userStore.isAuthorized],
			() => this.fetchData(),
			{fireImmediately: true}
		);

		this._subscriptions$.push(subscription);
	}

	onChange(param: void): void {
		return;
	}

	public getIsPackOpen = (contestId: number): boolean => {
		const contest = this._userHistoryStore.userHistory.find((e) => e.contestId === contestId);

		return Boolean(contest && contest.points !== null);
	};

	private fetchData() {
		if (this.contests.length === 0 || !this._userStore.isAuthorized) {
			return;
		}

		this._userHistoryStore.fetchHistory().catch(this.onError);
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showErrorModal(error);
	};
}
