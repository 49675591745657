import {injectable} from "inversify";
import {action, makeAutoObservable, observable} from "mobx";

export interface INotification {
	uuid?: string;
	message: string;
	duration?: number;
}

export interface INotificationsStore {
	get notifications(): INotification[];

	showNotification(notification: INotification): void;
}

@injectable()
export class NotificationsStore implements INotificationsStore {
	@observable private _notifications: INotification[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	public get notifications(): INotification[] {
		return this._notifications;
	}

	@action
	public showNotification(notification: INotification): void {
		const time = new Date().getTime();
		const uuid = `notification_${time}_${this.randomIndex()}`;
		const duration = notification.duration || 3000;

		const newNotification: INotification = {
			...notification,
			uuid,
			duration,
		};

		this._notifications.push(newNotification);

		setTimeout(() => {
			this.clearNotification(uuid);
		}, duration);
	}

	@action
	private clearNotification(uuid: string) {
		const index = this._notifications.findIndex((e) => e.uuid === uuid);

		if (index === -1) {
			return;
		}

		this._notifications = this._notifications.splice(index + 1, 1);
	}

	private randomIndex(): number {
		return Math.floor(Math.random() * 10000);
	}
}
