import {AxiosError} from "axios";
import {PRE_OPEN_KEY} from "data/constants";
import {Bindings} from "data/constants/bindings";
import {ContestType, ModalType} from "data/enums";
import {IApiResponse} from "data/services/http";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IModalTutorialData} from "data/types/modals";
import {ViewController} from "data/types/structure";
import {CardFlip} from "data/utils/cardFlip";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface IModalPackOpenController extends ViewController {
	close: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;

	get type(): string;

	get isPlayerProp(): boolean;
}

@injectable()
export class ModalPackOpenController implements IModalPackOpenController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore
	) {
		makeAutoObservable(this);
	}

	get isPlayerProp(): boolean {
		return this.activeContest?.type === ContestType.Player;
	}

	get type(): string {
		if (!this.activeContest) {
			return "";
		}
		return this.activeContest.type;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.PRE_OPEN_PACK;
	}

	private get modalData(): IModalTutorialData {
		return this._modalsStore.modalContent as IModalTutorialData;
	}

	private get activeContest() {
		return this._contestsStore.selectedContest;
	}

	public close = () => {
		if (!this.activeContest || this.modalData?.skipSave) {
			this._modalsStore.hideModal();
			return;
		}

		this._answersStore.isFirstPackOpen = true;
		CardFlip.ClearFlips();
		localStorage.setItem(`${PRE_OPEN_KEY}_${this.type}`, "true");
		this._answersStore.generateLineup(this.activeContest.id).catch(this.onError);
		this._modalsStore.hideModal();
		this.scrollToView();
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showErrorModal(error);
	};

	private scrollToView() {
		if (window.matchMedia("(max-width: 640px)").matches) {
			document.body.scrollTo({
				top: 110,
				left: 0,
				behavior: "smooth",
			});
		}
	}
}
