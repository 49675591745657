import {Bindings} from "data/constants/bindings";
import type {IRankingsApiProvider} from "data/providers/api/rankings.api.provider";
import type {IContestHistoryRanking} from "data/types/rankings";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";

export interface IUserHistoryStore {
	get isLoading(): boolean;

	get userHistory(): IContestHistoryRanking[];

	fetchHistory(): Promise<void>;
	getContestDataById(contestId: number): IContestHistoryRanking | undefined;
}

@injectable()
export class UserHistoryStore implements IUserHistoryStore {
	@observable private _isLoading: boolean = false;
	@observable private _userHistory: IContestHistoryRanking[] = [];

	constructor(
		@inject(Bindings.RankingsApiProvider) private _rankingsApiProvider: IRankingsApiProvider
	) {
		makeAutoObservable(this);
	}

	getContestDataById(contestId: number): IContestHistoryRanking | undefined {
		return this._userHistory.find((e) => e.contestId === contestId);
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public get userHistory(): IContestHistoryRanking[] {
		return this._userHistory;
	}

	public async fetchHistory(): Promise<void> {
		this._isLoading = true;
		try {
			const {data} = await this._rankingsApiProvider.userHistory();

			runInAction(() => {
				this._userHistory = data.success.rankings;
			});
		} catch (e) {
			return Promise.reject(e);
		} finally {
			this._isLoading = false;
		}
	}
}
