import {AxiosError} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse} from "data/services/http";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRankingsStore} from "data/stores/rankings/rankings.store";
import type {IRanking} from "data/types/rankings";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface IRankingsTableController extends ViewController {
	loadMore: () => void;
	getUserClass: (ranking: IRanking | null) => string;

	get i18n(): ILocalizationStore;

	get isLoading(): boolean;

	get isOverall(): boolean;

	get rankings(): IRanking[];

	get userRow(): IRanking | null;

	get isUserRowInList(): boolean;

	get hasNextPage(): boolean;
}

@injectable()
export class RankingsTableController implements IRankingsTableController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	public get hasNextPage(): boolean {
		return this._rankingsStore.hasNextPage;
	}

	get isLoading(): boolean {
		return this._rankingsStore.isLoading;
	}

	get isOverall(): boolean {
		return this._rankingsStore.isOverall;
	}

	get rankings(): IRanking[] {
		return this._rankingsStore.rankings;
	}

	get userRow(): IRanking | null {
		return this._rankingsStore.userRow;
	}

	get isUserRowInList() {
		if (!this.userRow) {
			return true;
		}

		return this.rankings.some((e) => e.userId === this.userRow?.userId);
	}

	public loadMore = () => {
		if (!this._rankingsStore.selectedContest) {
			return;
		}
		this._rankingsStore
			.fetchLoadMore(this._rankingsStore.selectedContest.id)
			.catch(this.onError);
	};

	public getUserClass = (ranking: IRanking | null): string => {
		return this.userRow?.userId === ranking?.userId ? "user" : "";
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	onChange(param: void): void {
		return;
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showErrorModal(error);
	};
}
