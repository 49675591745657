import {Platform, PostMessageType} from "data/enums";

export abstract class Platforming {
	public static set platform(value: Platform) {
		this._platform = value;
	}
	private static _platform: Platform = Platform.Web;

	public static get isApp(): boolean {
		return this._platform === Platform.App;
	}

	public static REDIRECT() {
		return this.platform === Platform.Web ? PostMessageType.Redirect : PostMessageType.Close;
	}
}
