import {Bindings} from "data/constants/bindings";
import {TournamentStatus} from "data/enums";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IPlayerCardsCommonEntity} from "data/types/common";
import type {IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

export interface IPlayerCardResultPreviewController
	extends ViewController<IPlayerCardsCommonEntity> {
	get contest(): IContest | undefined;

	get player(): IPlayer | undefined;

	get contestStatus(): string;
}

@injectable()
export class PlayerCardResultPreviewController implements IPlayerCardResultPreviewController {
	@observable private _position: number = Number.MAX_SAFE_INTEGER;
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore
	) {
		makeAutoObservable(this);
	}

	get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}

	get player(): IPlayer | undefined {
		return this._playersStore.getPlayerById(this.playerId);
	}

	get contestStatus(): string {
		if (this.tournament?.status === TournamentStatus.Scheduled) {
			return "";
		}
		return this.contest?.status || "";
	}

	private get tournament() {
		if (!this.contest) {
			return;
		}
		return this._tournamentsStore.getTournamentById(this.contest.tournamentId);
	}

	private get playerId(): number {
		return this._answersStore.lineup[this._position] || -1;
	}

	dispose(): void {
		return;
	}

	init(param: IPlayerCardsCommonEntity): void {
		this._contestId = param.contestId;
		this._position = param.position;
	}

	onChange(param: IPlayerCardsCommonEntity): void {
		this._contestId = param.contestId;
		this._position = param.position;
	}
}
