import {Bindings} from "data/constants/bindings";
import {TournamentStatus} from "data/enums";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import {type IChecksums, type IChecksumStore} from "data/stores/checksum/checksum.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";

/**
 * Constant for determine update frequency.
 */
const LIVE_SCORING_FETCH_TIMING = 1000 * 60;
const LIVE_SCORING_FETCH_TIMING_NON_ACTIVE = LIVE_SCORING_FETCH_TIMING * 5;

type IChecksumAction = Record<keyof IChecksums, () => Promise<void>>;

interface IParams {
	fetchTeam?: boolean;
}
export interface ILiveScoreController extends ViewController<IParams> {
	subscribeLiveScoring: () => void;
	unsubscribeLiveScoring: () => void;
}

@injectable()
export class LiveScoreController implements ILiveScoreController {
	@observable private _fetchTeam: boolean = false;
	protected readonly _actions: IChecksumAction;
	@observable protected _interval?: ReturnType<typeof setInterval>;
	@observable protected _isSubscribed: boolean = false;
	@observable private _subscriptions$: IReactionDisposer[] = [];

	constructor(
		@inject(Bindings.ChecksumStore) private _checksumStore: IChecksumStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
		this._actions = this.generateActions();
	}

	get updatedChecksum(): IChecksums {
		return this._checksumStore.changedChecksums;
	}

	private get isLive(): boolean {
		const contest = this._contestsStore.activeContest;
		const tournament = this._tournamentsStore.getTournamentById(contest?.tournamentId || 0);

		return tournament?.status === TournamentStatus.Playing;
	}

	/**
	 * Call to subscribe on checksum changes
	 */
	@action
	public subscribeLiveScoring() {
		if (this._isSubscribed) {
			return;
		}

		this._isSubscribed = true;

		void this._checksumStore.fetchChecksums();

		const timeout = this.isLive
			? LIVE_SCORING_FETCH_TIMING
			: LIVE_SCORING_FETCH_TIMING_NON_ACTIVE;
		this._interval = setInterval(() => {
			void this._checksumStore.fetchChecksums();
		}, timeout);
	}

	/**
	 * Stop checking changes
	 * called on dispose
	 * you can call it when you want to stop listen checksums, for example on the end of the game match/round/etc.
	 */
	@action
	public unsubscribeLiveScoring() {
		this._isSubscribed = false;

		if (this._interval) {
			clearInterval(this._interval);
		}
	}

	/**
	 * Check changed checksums and call actions
	 */
	@action
	callActions = () => {
		Object.keys(this.updatedChecksum).forEach((key) => {
			const action = this._actions[key];
			if (action && typeof action === "function") {
				void action();
			}
		});
	};

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
		this.unsubscribeLiveScoring();
	}

	async init(param: IParams) {
		this._fetchTeam = Boolean(param.fetchTeam);

		// Fetch checksums
		await this._checksumStore.fetchChecksums();

		// Subscribe on changes
		const subscription = reaction(() => this.updatedChecksum, this.callActions, {
			fireImmediately: true,
		});

		const liveSubscription = reaction(
			() => this.isLive,
			() => {
				this.unsubscribeLiveScoring();
				this.subscribeLiveScoring();
			},
			{fireImmediately: true}
		);

		this._subscriptions$.push(subscription);
		this._subscriptions$.push(liveSubscription);
	}

	public onChange(param: IParams) {
		this._fetchTeam = Boolean(param.fetchTeam);
	}

	/**
	 * Provide object of files you want to update
	 * for example: rounds
	 */
	private generateActions(): IChecksumAction {
		return {
			contests: () => {
				if (this._fetchTeam && this._contestsStore.selectedContest) {
					void this._answersStore.fetchAnswers(this._contestsStore.selectedContest.id);
				}
				return this._contestsStore.fetchContests();
			},
			tournaments: () => this._tournamentsStore.fetchTournaments(),
		};
	}
}
