import {Bindings} from "data/constants/bindings";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContest, IQuestion} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";

interface IParams {
	questionId: number | undefined;
	contestId: number;
}

export interface IFlipCardOutcomeUIController extends ViewController<IParams> {
	get i18n(): ILocalizationStore;

	get contest(): IContest | undefined;

	get question(): IQuestion | undefined;
}

@injectable()
export class FlipCardOutcomeUIController implements IFlipCardOutcomeUIController {
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;
	@observable private _questionId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore
	) {
		makeAutoObservable(this);
	}

	public get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}

	public get question(): IQuestion | undefined {
		return this.contest?.questions.find((question) => question.questionId === this._questionId);
	}

	dispose(): void {
		return;
	}

	init(param: IParams): void {
		this._contestId = param.contestId;
		this._questionId = param.questionId || Number.MAX_SAFE_INTEGER;
	}

	onChange(param: IParams): void {
		this._contestId = param.contestId;
		this._questionId = param.questionId || Number.MAX_SAFE_INTEGER;
	}
}
