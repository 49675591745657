import styled from "@emotion/styled";

export const Label = styled.label`
	cursor: pointer;
	text-align: left;
	font-size: 14px;
	line-height: 18px;
	font-weight: 500;
`;

export const ErrorText = styled.p`
	color: var(--invalid-color) !important;
	width: 100%;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

export * from "views/components/form/input.component";
export * from "views/components/form/checkbox.component";
export * from "views/components/form/radiobutton.component";
export * from "views/components/form/select.component";
