import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {IPlayerCardsCommonEntity} from "data/types/common";
import type {IFlipState, IContest, IQuestion} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {CardFlip} from "data/utils/cardFlip";
import {inject, injectable} from "inversify";
import {type IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";

interface IProps extends IPlayerCardsCommonEntity {
	onClick: (position: number) => void;
}

export interface IOutcomeCardController extends ViewController<IProps> {
	openCard: () => void;
	flip: () => void;

	get i18n(): ILocalizationStore;

	get isOpen(): boolean;

	get isFlipAvailable(): boolean;

	get isPartOfFlipAnimation(): boolean;

	get question(): IQuestion | undefined;

	get contest(): IContest | undefined;
}

@injectable()
export class OutcomeCardController implements IOutcomeCardController {
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _position: number = Number.MAX_SAFE_INTEGER;
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;
	@observable private _isOpen: boolean = false;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._isOpen;
	}

	get isFlipAvailable(): boolean {
		return !this._answersStore.isTeamSaved;
	}

	get question(): IQuestion | undefined {
		return this.contest?.questions.find((question) => question.questionId === this.outcomeId);
	}

	get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}

	private get outcomeId(): number | undefined {
		return this.lineup[this._position ?? -1];
	}

	private get lineup() {
		return this._answersStore.lineup;
	}

	public openCard = () => {
		if (!this._contestId || !this.outcomeId) {
			return;
		}

		this._isOpen = true;
		CardFlip.saveFlip(this._contestId, this.outcomeId);
		const openCards = this._answersStore.openCards;
		this._answersStore.openCards = [...openCards, this.outcomeId];
	};

	get isPartOfFlipAnimation(): boolean {
		if (!this.flipPayload) {
			return false;
		}
		return [this.flipPayload.from, this.flipPayload.to].includes(this.outcomeId || -1);
	}

	public get flipPayload(): IFlipState | undefined {
		return this._answersStore.flipPayload;
	}

	public flip = () => {
		if (!this.outcomeId) {
			return;
		}
		this._modalsStore.showModal(ModalType.TEAM_SAVE_OUTCOME, {swapId: this.outcomeId});
	};

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: IProps): void {
		this._position = param.position;
		this._contestId = param.contestId;

		const subscription = reaction(
			() => [this._contestId, this.outcomeId],
			() => this.checkIsOpen(param),
			{fireImmediately: true}
		);
		this._subscriptions$.push(subscription);
	}

	onChange(param: IProps): void {
		this._position = param.position;
		this._contestId = param.contestId;
	}

	private checkIsOpen(param: IProps) {
		if (!this._contestId || !this.outcomeId) {
			return;
		}
		if (CardFlip.getIsFlipped(this._contestId, this.outcomeId)) {
			this._isOpen = true;
		} else {
			const delay = param.immediateFlip ? 500 : 1200;
			const positionDelay = param.position * delay;
			const timeout = param.immediateFlip ? 1500 : 5500;
			setTimeout(() => {
				this.openCard();
				param.onClick(param.position);
			}, timeout + positionDelay);
		}
	}
}
