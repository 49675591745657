import {Bindings} from "data/constants/bindings";
import {ContestType} from "data/enums";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {IContest, IFlipState, IQuestion} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {wait} from "data/utils";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";

export interface ICardFlipController extends ViewController {
	get isPlayerCard(): boolean;

	get className(): string;

	get contest(): IContest | undefined;

	get flipPlayer(): IPlayer | undefined;

	get currentPlayer(): IPlayer | undefined;

	get flipQuestion(): IQuestion | undefined;

	get currentQuestion(): IQuestion | undefined;

	get questionPosition(): number;
}

@injectable()
export class CardFlipController implements ICardFlipController {
	@observable private _className: string = "";

	constructor(
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore
	) {
		makeAutoObservable(this);
	}

	public get className(): string {
		return this._className;
	}

	get contest() {
		return this._contestsStore.selectedContest;
	}

	get flipQuestion(): IQuestion | undefined {
		return this.contest?.questions?.find(
			(question) => question.questionId === this.flipPayload?.from
		);
	}

	get currentQuestion(): IQuestion | undefined {
		return this.contest?.questions?.find(
			(question) => question.questionId === this.flipPayload?.to
		);
	}

	get flipPlayer(): IPlayer | undefined {
		return this._playersStore.getPlayerById(this.flipPayload?.from || 0);
	}

	get currentPlayer(): IPlayer | undefined {
		return this._playersStore.getPlayerById(this.flipPayload?.to || 0);
	}

	get questionPosition(): number {
		const position = this._answersStore.lineup.findIndex((id) => id === this.flipPayload?.to);
		return position === -1 ? 0 : position;
	}

	get isPlayerCard(): boolean {
		return this._contestsStore.selectedContest?.type === ContestType.Player;
	}

	private get flipPayload(): IFlipState | undefined {
		return this._answersStore.flipPayload;
	}

	dispose(): void {
		return;
	}

	async init(param: void) {
		await wait(1000);
		runInAction(() => {
			this._className = "flip";
		});

		await wait(2000);
		runInAction(() => {
			this._answersStore.isFlipCardAnimation = false;
		});
	}

	onChange(param: void): void {
		return;
	}
}
