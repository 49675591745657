import {AxiosResponse} from "axios";
import {OVERALL_LEAGUE_ID} from "data/constants";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {
	IContestHistoryRankings,
	IGameBar,
	IRankingsRequestPayload,
	IRankingsResponse,
} from "data/types/rankings";
import {inject, injectable} from "inversify";

export type TGameBarResponse = IApiResponse<IGameBar>;
export type TRankingsResponse = IApiResponse<IRankingsResponse>;
export type THistoryRankingsResponse = IApiResponse<IContestHistoryRankings>;

export interface IRankingsApiProvider {
	gameBar(): Promise<AxiosResponse<TGameBarResponse>>;

	rankings(
		payload: IRankingsRequestPayload,
		isOverall: boolean
	): Promise<AxiosResponse<TRankingsResponse>>;

	userHistory(): Promise<AxiosResponse<THistoryRankingsResponse>>;
}

@injectable()
export class RankingsApiProvider implements IRankingsApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	gameBar() {
		return this._http.get<TGameBarResponse>("fantasy/rankings/gamebar");
	}

	rankings(payload: IRankingsRequestPayload, isOverall: boolean) {
		const {contestId, ...rest} = payload;
		const URL = isOverall
			? `fantasy/rankings/${contestId}/overall`
			: `fantasy/rankings/${contestId}/league/${OVERALL_LEAGUE_ID}`;
		return this._http.get<TRankingsResponse>(URL, rest);
	}

	userHistory() {
		return this._http.get<THistoryRankingsResponse>("fantasy/rankings/user");
	}
}
