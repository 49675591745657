import {API_URL, CONTENT_JSON_URL, JSON_URL} from "data/constants";
import {Bindings} from "data/constants/bindings";
import {AnswersApiProvider, IAnswersApiProvider} from "data/providers/api/answers.api.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {
	type ILeaguesApiProvider,
	LeaguesApiProvider,
} from "data/providers/api/leagues.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {IRankingsApiProvider, RankingsApiProvider} from "data/providers/api/rankings.api.provider";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {AnswersStore, IAnswersStore} from "data/stores/answers/answers.store";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {ContestsStore, IContestsStore} from "data/stores/contests/contests.store";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {type ILeaguesStore, LeaguesStore} from "data/stores/leagues/leagues.store";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {
	INotificationsStore,
	NotificationsStore,
} from "data/stores/notifications/notifications.store";
import {type IPlayersStore, PlayersStore} from "data/stores/players/players.store";
import {IRankingsStore, RankingsStore} from "data/stores/rankings/rankings.store";
import {type IRoundsStore, RoundsStore} from "data/stores/rounds/rounds.store";
import {ISettingsStore, SettingsStore} from "data/stores/settings/settings.store";
import {type ISquadsStore, SquadsStore} from "data/stores/squads/squads.store";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {ITournamentsStore, TournamentsStore} from "data/stores/tournaments/tournaments.store";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {IUserHistoryStore, UserHistoryStore} from "data/stores/user_history/user_history.store";
import {Container, ContainerModule, type interfaces} from "inversify";
import {
	AppHeaderController,
	IAppHeaderController,
} from "views/components/app_header/app_header.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	CardFlipController,
	ICardFlipController,
} from "views/components/cards/card_flip/card_flip.controller";
import {
	IOutcomeCardController,
	OutcomeCardController,
} from "views/components/cards/outcome_card/outcome_card.controller";
import {
	IOutcomeCardResultController,
	OutcomeCardResultController,
} from "views/components/cards/outcome_card_result/outcome_card_result.controller";
import {
	IOutcomeCardResultPreviewController,
	OutcomeCardResultPreviewController,
} from "views/components/cards/outcome_card_result_preview/outcome_card_result_preview.controller";
import {
	IPlayerCardController,
	PlayerCardController,
} from "views/components/cards/player_card/player_card.controller";
import {
	IPlayerCardResultController,
	PlayerCardResultController,
} from "views/components/cards/player_card_result/player_card_result.controller";
import {
	IPlayerCardResultPreviewController,
	PlayerCardResultPreviewController,
} from "views/components/cards/player_card_result_preview/player_card_result_preview.controller";
import {
	ChampionshipCurrentController,
	IChampionshipCurrentController,
} from "views/components/championship_current/championship_current.controller";
import {
	ChampionshipCurrentListController,
	IChampionshipCurrentListController,
} from "views/components/championship_current_list/championship_current_list.controller";
import {
	ChampionshipsCompleteController,
	IChampionshipsCompleteController,
} from "views/components/championships_complete/championships_complete.controller";
import {
	ChampionshipsUpcomingController,
	IChampionshipsUpcomingController,
} from "views/components/championships_upcoming/championships_upcoming.controller";
import {
	ContestPackController,
	IContestPackController,
} from "views/components/contest_pack/contest_pack.controller";
import {
	ContestPillController,
	IContestPillController,
} from "views/components/contest_pill/contest_pill.controller";
import {
	ContestStatusController,
	IContestStatusController,
} from "views/components/contest_status/contest_status.controller";
import {
	FlipCardOutcomeUIController,
	IFlipCardOutcomeUIController,
} from "views/components/flip_card_outcome_ui/flip_card_outcome_ui.controller";
import {
	FlipCardUIController,
	IFlipCardUIController,
} from "views/components/flip_card_ui/flip_card_ui.controller";
import {
	GameFooterController,
	IGameFooterController,
} from "views/components/game_footer/game_footer.controller";
import {GameBarController, IGameBarController} from "views/components/gamebar/game_bar.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ILandingBannerController,
	LandingBannerController,
} from "views/components/landing_banner/landing_banner.controller";
import {
	type ILeagueInviteFormController,
	LeagueInviteFormController,
} from "views/components/league_invite_form/league_invite_form.controller";
import {
	type IModalConfirmController,
	ModalConfirmController,
} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	type IModalErrorController,
	ModalErrorController,
} from "views/components/modals/modal_error/modal_error.controller";
import {
	IModalPackOpenController,
	ModalPackOpenController,
} from "views/components/modals/modal_open_pack/modal_open_pack.controller";
import {
	IModalSecondaryRegistrationController,
	ModalSecondaryRegistrationController,
} from "views/components/modals/modal_secondary_registration/modal_secondary_registration.controller";
import {
	IModalTeamOutcomeSaveController,
	ModalTeamOutcomeSaveController,
} from "views/components/modals/modal_team_outcome_save/modal_team_outcome_save.controller";
import {
	IModalTeamSaveController,
	ModalTeamSaveController,
} from "views/components/modals/modal_team_save/modal_team_save.controller";
import {
	INavigationController,
	NavigationController,
} from "views/components/navigation/navigation.controller";
import {
	INotificationController,
	NotificationController,
} from "views/components/notification/notification.controller";
import {
	IOutcomePointsController,
	OutcomePointsController,
} from "views/components/outcome_points/outcome_points.controller";
import {
	IRankingsFiltersController,
	RankingsFiltersController,
} from "views/components/rankings_filters/rankings_filters.controller";
import {
	IRankingsTableController,
	RankingsTableController,
} from "views/components/rankings_table/rankings_table.controller";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {ISharingController, SharingController} from "views/components/sharing/sharing.controller";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secret_gate/secret_gate.controller";
import {
	ContestSummaryController,
	IContestSummaryController,
} from "views/pages/contest_summary/contest_summary.controller";
import {
	ContestViewController,
	IContestViewController,
} from "views/pages/contest_view/contest_view.controller";
import {
	CreateLeagueController,
	type ICreateLeagueController,
} from "views/pages/create_league/create_league.controller";
import {
	ForgotPasswordController,
	type IForgotPasswordController,
} from "views/pages/forgot_password/forgot_password.controller";
import {HelpController, type IHelpController} from "views/pages/help/help.controller";
import {
	type IJoinLeaguesController,
	JoinLeaguesController,
} from "views/pages/join_leagues/join_leagues.controller";
import {type ILeagueController, LeagueController} from "views/pages/league/league.controller";
import {
	type ILeagueAboutController,
	LeagueAboutController,
} from "views/pages/league_about/league_about.controller";
import {
	type ILeagueInviteController,
	LeagueInviteController,
} from "views/pages/league_invite/league_invite.controller";
import {
	type ILeagueSettingsController,
	LeagueSettingsController,
} from "views/pages/league_settings/league_settings.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {
	type IMyLeaguesController,
	MyLeaguesController,
} from "views/pages/my_leagues/my_leagues.controller";
import {
	type IResetPasswordController,
	ResetPasswordController,
} from "views/pages/reset_password/reset_password.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<IAnswersApiProvider>(Bindings.AnswersApiProvider).to(AnswersApiProvider);
	bind<ILeaguesApiProvider>(Bindings.LeaguesApiProvider).to(LeaguesApiProvider);
	bind<IRankingsApiProvider>(Bindings.RankingsApiProvider).to(RankingsApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);
	bind<IContestsStore>(Bindings.ContestsStore).to(ContestsStore).inSingletonScope();
	bind<ITournamentsStore>(Bindings.TournamentsStore).to(TournamentsStore).inSingletonScope();
	bind<IAnswersStore>(Bindings.AnswersStore).to(AnswersStore).inSingletonScope();

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);
	bind<ILeaguesStore>(Bindings.LeaguesStore).to(LeaguesStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<INotificationsStore>(Bindings.NotificationsStore)
		.to(NotificationsStore)
		.inSingletonScope();
	bind<IRankingsStore>(Bindings.RankingsStore).to(RankingsStore).inSingletonScope();
	bind<IUserHistoryStore>(Bindings.UserHistoryStore).to(UserHistoryStore).inSingletonScope();
	bind<ISettingsStore>(Bindings.SettingsStore).to(SettingsStore).inSingletonScope();
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<IForgotPasswordController>(Bindings.ForgotPasswordController).to(ForgotPasswordController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IMyLeaguesController>(Bindings.MyLeaguesController).to(MyLeaguesController);
	bind<IJoinLeaguesController>(Bindings.JoinLeaguesController).to(JoinLeaguesController);
	bind<ICreateLeagueController>(Bindings.CreateLeagueController).to(CreateLeagueController);
	bind<ILeagueController>(Bindings.LeagueController).to(LeagueController);
	bind<ILeagueInviteController>(Bindings.LeagueInviteController).to(LeagueInviteController);
	bind<ILeagueSettingsController>(Bindings.LeagueSettingsController).to(LeagueSettingsController);
	bind<ILeagueAboutController>(Bindings.LeagueAboutController).to(LeagueAboutController);
	bind<ILeagueInviteFormController>(Bindings.LeagueInviteFormController).to(
		LeagueInviteFormController
	);
	bind<IModalErrorController>(Bindings.ModalErrorController).to(ModalErrorController);
	bind<IModalConfirmController>(Bindings.ModalConfirmController).to(ModalConfirmController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<ILandingBannerController>(Bindings.LandingBannerController).to(LandingBannerController);
	bind<INavigationController>(Bindings.NavigationController).to(NavigationController);
	bind<IChampionshipCurrentController>(Bindings.ChampionshipCurrentController).to(
		ChampionshipCurrentController
	);
	bind<IContestViewController>(Bindings.ContestViewController).to(ContestViewController);
	bind<IPlayerCardController>(Bindings.PlayerCardController).to(PlayerCardController);
	bind<IModalTeamSaveController>(Bindings.ModalTeamSaveController).to(ModalTeamSaveController);
	bind<IModalTeamOutcomeSaveController>(Bindings.ModalTeamOutcomeSaveController).to(
		ModalTeamOutcomeSaveController
	);
	bind<IGameFooterController>(Bindings.GameFooterController).to(GameFooterController);
	bind<IFlipCardUIController>(Bindings.FlipCardUIController).to(FlipCardUIController);
	bind<IFlipCardOutcomeUIController>(Bindings.FlipCardOutcomeUIController).to(
		FlipCardOutcomeUIController
	);
	bind<IContestStatusController>(Bindings.ContestStatusController).to(ContestStatusController);
	bind<IContestPackController>(Bindings.ContestPackController).to(ContestPackController);
	bind<IOutcomeCardController>(Bindings.OutcomeCardController).to(OutcomeCardController);
	bind<IPlayerCardResultController>(Bindings.PlayerCardResultController).to(
		PlayerCardResultController
	);
	bind<IOutcomeCardResultController>(Bindings.OutcomeCardResultController).to(
		OutcomeCardResultController
	);
	bind<IContestSummaryController>(Bindings.ContestSummaryController).to(ContestSummaryController);
	bind<IOutcomeCardResultPreviewController>(Bindings.OutcomeCardResultPreviewController).to(
		OutcomeCardResultPreviewController
	);
	bind<IPlayerCardResultPreviewController>(Bindings.PlayerCardResultPreviewController).to(
		PlayerCardResultPreviewController
	);
	bind<IGameBarController>(Bindings.GameBarController).to(GameBarController);
	bind<IContestPillController>(Bindings.ContestPillController).to(ContestPillController);
	bind<IModalSecondaryRegistrationController>(Bindings.ModalSecondaryRegistrationController).to(
		ModalSecondaryRegistrationController
	);
	bind<INotificationController>(Bindings.NotificationController).to(NotificationController);
	bind<IRankingsFiltersController>(Bindings.RankingsFiltersController).to(
		RankingsFiltersController
	);
	bind<IAppHeaderController>(Bindings.AppHeaderController).to(AppHeaderController);
	bind<IChampionshipsUpcomingController>(Bindings.ChampionshipsUpcomingController).to(
		ChampionshipsUpcomingController
	);
	bind<IChampionshipsCompleteController>(Bindings.ChampionshipsCompleteController).to(
		ChampionshipsCompleteController
	);
	bind<IRankingsTableController>(Bindings.RankingsTableController).to(RankingsTableController);
	bind<ICardFlipController>(Bindings.CardFlipController).to(CardFlipController);
	bind<ISharingController>(Bindings.SharingController).to(SharingController);
	bind<IOutcomePointsController>(Bindings.OutcomePointsController).to(OutcomePointsController);
	bind<IModalPackOpenController>(Bindings.ModalPackOpenController).to(ModalPackOpenController);
	bind<IChampionshipCurrentListController>(Bindings.ChampionshipCurrentListController).to(
		ChampionshipCurrentListController
	);
});
