import {AxiosError} from "axios";
import {PRE_OPEN_KEY} from "data/constants";
import {Bindings} from "data/constants/bindings";
import {ContestType, ModalType} from "data/enums";
import type {IApiResponse} from "data/services/http";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IContestsStore} from "data/stores/contests/contests.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IAnswersResponse, IContest} from "data/types/contest";
import {ViewController} from "data/types/structure";
import {CardFlip} from "data/utils/cardFlip";
import {createConnextraScriptTag} from "data/utils/connextra";
import {inject, injectable} from "inversify";
import {action, type IReactionDisposer, makeAutoObservable, observable, reaction} from "mobx";
import type {NavigateFunction} from "react-router-dom";

interface IParams {
	contestId: number;
	navigate: NavigateFunction;
}

export interface IContestViewController extends ViewController<IParams> {
	openPack: () => void;
	swipeLeft: () => void;
	swipeRight: () => void;
	navigateToCard: (position: number) => void;
	toPrevCard: () => void;
	toNextCard: () => void;

	get isLineupExists(): boolean;

	get isPrevDisabled(): boolean;

	get isNextDisabled(): boolean;

	get isLoading(): boolean;

	get contest(): IContest | undefined;

	get contestType(): ContestType;

	get isPackOpen(): boolean;

	get cardsClass(): string;

	get packClass(): string;

	get animatedClass(): string;

	get forceAnimationSkip(): boolean;
}

@injectable()
export class ContestViewController implements IContestViewController {
	@observable private _navigate: NavigateFunction | undefined;
	@observable private _swipeIndex = 2;
	@observable private _isPackOpen: boolean = false;
	@observable private _isAnimated: boolean = false;
	@observable private _forceAnimationSkip: boolean = false;
	@observable private _subscriptions$: IReactionDisposer[] = [];
	@observable private _contestId: number = Number.MAX_SAFE_INTEGER;

	constructor(
		@inject(Bindings.ContestsStore) private _contestsStore: IContestsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isLineupExists(): boolean {
		return this.lineup?.length > 0;
	}

	get isPrevDisabled(): boolean {
		if (!this._isAnimated) {
			return true;
		}
		return this._swipeIndex === 1;
	}

	get isNextDisabled(): boolean {
		if (!this._isAnimated) {
			return true;
		}
		return this._swipeIndex === 3;
	}

	public get forceAnimationSkip(): boolean {
		return this._forceAnimationSkip;
	}

	get cardsClass(): string {
		return `card-${this._swipeIndex}`;
	}

	get packClass(): string {
		return this.isPackOpen ? "open" : "";
	}

	get isLoading(): boolean {
		return this._answersStore.isLoading;
	}

	get animatedClass() {
		return this._isAnimated ? "animated" : "";
	}

	public get isPackOpen(): boolean {
		return this._isPackOpen;
	}

	public get contestType(): ContestType {
		return this.contest?.type || ContestType.Player;
	}

	get contest(): IContest | undefined {
		return this._contestsStore.getContestById(this._contestId);
	}

	private get lineup() {
		return this._answersStore.lineup;
	}

	private get isTeamSaved() {
		return this._answersStore.isTeamSaved;
	}

	@action
	public swipeLeft = () => {
		if (this._swipeIndex > 2) {
			return;
		}
		this._swipeIndex += 1;
	};

	@action
	public swipeRight = () => {
		if (this._swipeIndex < 2) {
			return;
		}
		this._swipeIndex -= 1;
	};

	@action navigateToCard = (position: number) => {
		this._swipeIndex = position + 1;
	};

	dispose(): void {
		this._subscriptions$.forEach((dispose) => dispose());
	}

	init(param: IParams): void {
		this.initParams(param);

		this._answersStore.isLoading = true;

		const subscription = reaction(
			() => this.contest,
			() => this.fetchContestData(),
			{fireImmediately: true}
		);
		const lineupSubscription = reaction(
			() => this.lineup,
			() => this.checkLineup(),
			{fireImmediately: true}
		);

		const completeSubscription = reaction(
			() => [
				this.isTeamSaved,
				this._answersStore.isFirstPackOpen,
				this._answersStore.isFlipCardAnimation,
			],
			() => this.checkAndRedirect(),
			{fireImmediately: true}
		);

		const packOpenAnimateSubscription = reaction(
			() => this._isPackOpen,
			() => {
				if (this.isPackOpen) {
					this._isAnimated = true;
				}
			},
			{delay: 4000}
		);

		this._subscriptions$.push(subscription);
		this._subscriptions$.push(lineupSubscription);
		this._subscriptions$.push(completeSubscription);
		this._subscriptions$.push(packOpenAnimateSubscription);
	}

	onChange(param: IParams): void {
		this.initParams(param);
	}

	public openPack = () => {
		void this.generatePack();
	};

	public checkLineup() {
		if (this.lineup.length > 0) {
			this._isPackOpen = true;
		}
	}

	@action
	public toNextCard = () => {
		this._swipeIndex += 1;
	};

	@action
	public toPrevCard = () => {
		this._swipeIndex -= 1;
	};

	private get user() {
		return this._userStore.user;
	}

	private fetchContestData() {
		if (!this.contest) {
			return;
		}

		this._contestsStore.selectedContest = this.contest;
		this._answersStore
			.fetchAnswers(this.contest.id)
			.then((res) => {
				const response = res as IAnswersResponse;
				if (response?.lineup?.length > 0) {
					this._forceAnimationSkip = true;
					this._isAnimated = true;
				} else {
					this.generatePack();
				}
			})
			.catch(this.onError);
	}

	private get type() {
		return this.contest?.type || ContestType.Player;
	}

	private generatePack() {
		if (!this._contestId || this.isLoading || this._answersStore.isFirstPackOpen) {
			return;
		}

		if (this.user) {
			createConnextraScriptTag(`f2p_fantasyflip_loggedin?AccountID=${this.user.id}`);
		}

		const isFirstOpen = localStorage.getItem(`${PRE_OPEN_KEY}_${this.type}`) === null;
		if (isFirstOpen) {
			this._modalsStore.showModal(ModalType.PRE_OPEN_PACK);
			return;
		}

		this._answersStore.isFirstPackOpen = true;
		CardFlip.ClearFlips();
		this._answersStore.generateLineup(this._contestId).catch(this.onError);

		this.scrollToPack();
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showErrorModal(error);
	};

	private checkAndRedirect() {
		if (this._answersStore.isFlipCardAnimation) {
			return;
		}
		if (this.isTeamSaved && !this._answersStore.isFirstPackOpen) {
			this._navigate?.(`/contest/${this._contestId}/summary`);
		}
	}

	private initParams(param: IParams) {
		this._contestId = param.contestId;
		this._navigate = param.navigate;
	}

	private scrollToPack() {
		if (window.matchMedia("(max-width: 640px)").matches) {
			setTimeout(() => {
				document.body.scrollTo({
					top: 110,
					left: 0,
					behavior: "smooth",
				});
			}, 500);
		}
	}
}
